const eventsDbHeaders = [
  'name',
  'location',
  'link',
  'alt',
  // 'tags',
  'img',
  'text',
  'time',
  'stores.name',
];

export default eventsDbHeaders;
