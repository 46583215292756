import React, { Fragment, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import initialState from './state';
import actions from './actions';
import getTempFiles from './getTempFiles';

import FourOhFour from 'Comps/FourOhFour';
import Components from './Components/Components';

export default function FileUpload(props) {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    (async () => {
      try {
        const allTempFiles = await getTempFiles();

        setState({ ...state, tempCsvFiles: allTempFiles });
      } catch (err) {
        console.error(err);
        setState({ brandsError: true });
      }
    })();
  }, []);

  const dispatch = (action, ...args) => {
    return action(setState, state, ...args);
  };
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CSV Files Upload</title>
      </Helmet>
      <section className="csv-file-upload">
        <Components state={state} dispatch={dispatch} actions={actions} />
      </section>
    </Fragment>
  );
}
