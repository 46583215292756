export default {
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  phone: 'phone',
  address: 'address',
  city: 'city',
  zipcode: 'zipcode',
  state: 'state',
};
