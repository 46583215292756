import React from 'react';
import { MDBBtn, MDBCol } from 'mdbreact';
import ErrorLinksForWasabi from './ErrorLinksForWasabi';
import FileUpload from '../FileUpload';
import Modals from 'Comps/Modals';
import CsvCollection from '../csv/CsvCollection';

export default function CsvUpload(props) {
  const { dispatch, actions, state, createWasabiLinks } = props;
  const { tempCsvFiles, imgLinkError, wasabiLinksCreated } = state;

  const modal = (
    <Modals
      btnName="Download files"
      header={'Files for download'}
      body={
        tempCsvFiles && tempCsvFiles.length > 0 ? (
          <div className="mx-3">
            <CsvCollection {...props} />
          </div>
        ) : null
      }
    />
  );

  return (
    <MDBCol size="12" className="pl-0">
      <FileUpload {...props} />
      {wasabiLinksCreated ? (
        <MDBBtn
          onClick={(e) => dispatch(actions.uploadCsvFile, createWasabiLinks, e)}
          color="primary"
          size="sm">
          Submit CSV with Links
        </MDBBtn>
      ) : (
        <MDBBtn
          onClick={(e) =>
            createWasabiLinks
              ? dispatch(actions.saveImgLinksToWasabi, e)
              : dispatch(actions.uploadCsvFile, createWasabiLinks, e)
          }
          color="primary"
          size="sm">
          Submit
        </MDBBtn>
      )}

      {imgLinkError && Object.keys(imgLinkError).length > 0 ? (
        <ErrorLinksForWasabi imgLinkError={imgLinkError} />
      ) : null}
    </MDBCol>
  );
}
