import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import { MDBFileInput } from 'mdbreact';
import FormInput from 'Comps/FormInput';
import InputField from 'Comps/InputField';
import { useForm } from 'react-hook-form';

const NewSubmission = React.memo(function NewSubmission({ actions }) {
  const { register, handleSubmit, errors } = useForm();
  const [file, getFile] = useState({});
  const [fileError, setError] = useState(false);
  const { uploadSubmission } = actions;

  const onSubmit = (data) => {
    if (!file && _isEmpty(file)) return setError(true);
    uploadSubmission(data, file);
  };

  const inputs = [
    {
      id: 'name',
      label: 'Name',
      hint: 'Pet name and costume info',
      constraints: {
        required: { value: true, message: 'Cannot be empty' },
        shouldFocusError: true,
      },
    },
    {
      id: 'imageAlt',
      label: 'Image Alt',
      hint: 'Brief description',
      constraints: {
        required: { value: true, message: 'Cannot be empty' },
        shouldFocusError: true,
      },
    },
    {
      id: 'holidayName',
      label: 'Holiday',
      hint: 'Holiday name. Please use lowercase',
      constraints: {
        required: { value: true, message: 'Cannot be empty' },
        shouldFocusError: true,
      },
    },
  ];

  return (
    <>
      <FormInput onSubmit={handleSubmit(onSubmit)} buttonText={'Add new'}>
        {inputs.map((input, i) => (
          <InputField
            key={i}
            {...input}
            errors={errors}
            register={register}
            formFor="holidaySubmissions"
          />
        ))}
        <MDBFileInput
          accept="image/*"
          getValue={([uploadedFile]) => {
            const { type } = uploadedFile || {};
            if (/\.(jpe?g|png|gif|bmp)$/i.test('.' + type.slice(type.indexOf('/') + 1))) {
              return getFile(uploadedFile);
            }
            setError(true);
          }}
        />
        {fileError && (
          <div className="mb-2 text-danger font-weight-bolder">
            *** Missing image file or file is not an image
          </div>
        )}
      </FormInput>
    </>
  );
});

NewSubmission.propTypes = {
  actions: PropTypes.object,
};

export default NewSubmission;
