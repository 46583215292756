import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { MDBContainer, MDBRow, MDBCol, MDBInput, MDBSelect } from 'mdbreact';
import Logo from 'Comps/Logo/logo';
import Form from './Components/form';
import CcpaFooter from './Components/ccpaFooter';
import TextDetails from './Components/textDetails';
import requestTypes from './requestTypes';

const Ccpa = React.memo(function Ccpa() {
  const requestOptions = [
    { text: 'Do not sell my information', value: requestTypes.DO_NOT_SELL },
    { text: 'Request for my information', value: requestTypes.CCPA_REQUEST },
  ];
  const [requestType, setRequestType] = useState(requestTypes.DO_NOT_SELL);
  const [requestInfo, setRequestInfo] = useState(true);
  const [options] = useState(requestOptions);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Centinela Feed and Pet Supplies CCPA Request</title>
      </Helmet>
      <MDBContainer>
        <Logo />
        <MDBCol size="12" className="text-center mt-5">
          <h1>CCPA Privacy Request</h1>
        </MDBCol>
        <MDBCol size="12" sm="5">
          <MDBSelect
            getValue={(value) => setRequestType(value[0])}
            options={options}
            selected={'Do not sell my information'}
          />
        </MDBCol>
        {requestType === requestTypes.CCPA_REQUEST && (
          <MDBRow>
            <MDBCol size="12" className="my-3">
              <MDBInput
                onClick={() => setRequestInfo(true)}
                checked={requestInfo}
                label="Request for my information"
                type="radio"
                id="requestInfo"
              />
            </MDBCol>
            <MDBCol size="12" className="my-3">
              <MDBInput
                onClick={() => setRequestInfo(false)}
                checked={!requestInfo}
                label="Delete my information"
                type="radio"
                id="deleteInfo"
              />
            </MDBCol>
          </MDBRow>
        )}
        <Form requestType={requestType} requestInfo={requestInfo} />
        <MDBRow>
          <MDBCol size="12">
            <TextDetails />
          </MDBCol>
          <MDBCol size="12">
            <CcpaFooter />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
});

export default Ccpa;
