import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBSelect } from 'mdbreact';
import Header from '@Ui/Header';

import StoreCards from './StoreCards/index';

export default function Components(props) {
  const { state, dispatch, actions } = props;
  return (
    <MDBContainer>
      <section className="stores" id="stores">
        <MDBRow>
          <MDBCol size="12">
            <Header headerTag="h1" text="Centinela Feed Stores" />
          </MDBCol>
          <MDBCol size="12" sm="4">
            <MDBSelect
              getValue={(selectedVal) => dispatch(actions.chooseStore, selectedVal)}
              options={state.mdbSelectOpts}
              label="Find store"
              selected="Select store"
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <StoreCards {...props} />
        </MDBRow>
      </section>
    </MDBContainer>
  );
}
