import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InputMask from 'react-input-mask';
import styles from './inputField.module.scss';

const propTypes = {
  label: PropTypes.string.isRequired,
  arialabel: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  register: PropTypes.func,
  constraints: PropTypes.object,
  type: PropTypes.string,
  required: PropTypes.bool,
  _onChange: PropTypes.func,
};

const MaskedFieldInput = React.forwardRef(function MaskedFieldInput(
  { label, id, type, arialabel, name, placeholder, mask, required, errors, onChange, onBlur },
  ref,
) {
  return (
    <div className="md-form">
      <InputMask
        type={type || 'text'}
        aria-label={label}
        id={id}
        className={classNames('form-control active', styles.input)}
        aria-disabled="false"
        placeholder={placeholder}
        mask={mask}
        required={required}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        ref={ref}
      />
      <label
        aria-labelledby={arialabel}
        className={classNames('active', styles.maskedLabel)}
        htmlFor={id}>
        {label}
      </label>
      {errors && errors[name] && <div className={styles.error}>{errors[id].message}</div>}
    </div>
  );
});

MaskedFieldInput.propTypes = propTypes;
export default MaskedFieldInput;
