import React, { Fragment, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Axios from 'axios';
import urls from 'Utils/urls';

import initialState from './state';
import actions from './actions';

import Components from './Components/Components';

export default function Trainers(props) {
  const [state, setState] = useState(initialState);

  const dispatch = (action, ...args) => {
    return action(state, setState, ...args);
  };

  useEffect(() => {
    const init = async () => {
      try {
        const { status, data } = await Axios.get(`${urls.dbApi}/trainers/get-trainers`);
        if (status === 200) {
          setState({ ...state, trainers: data });
        }
      } catch (err) {
        console.error(err);
        setState({ ...state, error: true });
      }
    };
    init();
  }, []);

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Trainer Information</title>
      </Helmet>
      <Components dispatch={dispatch} state={state} actions={actions} />
    </Fragment>
  );
}
