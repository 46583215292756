const initialState = {
  events: [],
  selectedEvents: [],
  storeEventsInput: [],
  newStoreMdbInputs: [],
  eventError: false,
  newEventErr: false,
};

export default initialState;
