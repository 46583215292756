import React, { Fragment } from 'react';
import { MDBRow, MDBCol, MDBBtn } from 'mdbreact';
import createInput from '../../../Components/createInput';
import Error from '../../../Components/ResultHandlers/Error';
import ImgUploadInfo from '../../../Components/ImgUploadInfo';

import styles from '../styles/index.module.scss';

export default function TrainerInfo(props) {
  const { dispatch, actions, state } = props;

  const { _id, name, img, text, website, video_url, trainerIndex } = props.trainer;
  const [nameVal, nameInput] = createInput({
    name: 'trainer-name',
    initialVal: name,
    label: 'Trainer Name',
  });

  const [textInfo, infoInput] = createInput({
    name: 'trainer-info',
    initialVal: text,
    label: 'Trainer Info',
    type: 'textarea',
  });

  const [imgLink, imgInput] = createInput({
    name: 'trainer-img',
    initialVal: img,
    label: 'Trainer image link',
  });

  const [websiteLink, websiteInput] = createInput({
    name: 'trainer-website',
    initialVal: website,
    label: 'Trainer website link',
  });

  const [videoLink, videoLinkInput] = createInput({
    name: 'trainer-website',
    initialVal: video_url,
    label: 'Trainer video link',
  });

  return (
    <Fragment>
      <MDBRow>
        <MDBCol size="12" sm="6">
          {nameInput}
        </MDBCol>

        <MDBCol size="12">{infoInput}</MDBCol>

        <MDBCol size="12">{websiteInput}</MDBCol>

        <MDBCol size="12">{videoLinkInput}</MDBCol>

        <MDBCol size="5" className="mb-3">
          <div>Trainer Image:</div>
          <img src={imgLink} alt={`${name} image.`} className={`${styles.trainerImg} img-fluid`} />
          {imgInput}
          <ImgUploadInfo />
        </MDBCol>

        <MDBCol size="12">
          <MDBBtn
            onClick={(e) =>
              dispatch(actions.updateTrainerInfo, { textInfo, nameVal, _id, imgLink, websiteLink, videoLink }, e)
            }
            color="primary">
            Update Trainer Info
          </MDBBtn>

          <MDBBtn
            onClick={(e) => dispatch(actions.deleteTrainer, { _id, trainerIndex }, e)}
            color="danger">
            Delete Trainer
          </MDBBtn>

          {state.error ? (
            <Error message={'Unable to update or delete trainer. Please try again.'} />
          ) : null}
        </MDBCol>
      </MDBRow>
    </Fragment>
  );
}
