import React, { useState } from 'react';
import { MDBDataTable } from 'mdbreact';

/*
columns: [
      {
        label: 'Name',
        field: 'name',
        width: 150,
        attributes: {
          'aria-controls': 'DataTable',
          'aria-label': 'Name',
        },
      },
      {
        label: 'Position',
        field: 'position',
        width: 270,
      },
      {
        label: 'Office',
        field: 'office',
        width: 200,
      },
      {
        label: 'Age',
        field: 'age',
        sort: 'asc',
        width: 100,
      },
      {
        label: 'Start date',
        field: 'date',
        sort: 'disabled',
        width: 150,
      },
      {
        label: 'Salary',
        field: 'salary',
        sort: 'disabled',
        width: 100,
      },
    ],
    rows: [
      {
        name: 'Tiger Nixon',
        position: 'System Architect',
        office: 'Edinburgh',
        age: '61',
        date: '2011/04/25',
        salary: '$320',
      },
    */
/*
      0: {parent: "SLCTBS", child: Array(45), childCount: 45, totalCount: 46, brand: "[Sample] PET Food 1"}

*/
export default function InventoryTable(props) {
  const data = {
    columns: [
      {
        label: 'Parent',
        field: 'parent',
        attributes: {
          'aria-controls': 'DataTable',
          'aria-label': 'Name',
        },
      },
      {
        label: 'Child Skus',
        field: 'child',
      },
      {
        label: 'Child Skus Count',
        field: 'childCount',
      },
      {
        label: 'Total Skus',
        field: 'totalCount',
      },
      {
        label: 'Brand',
        field: 'brand',
      },
    ],
    rows: props.inventoryCountData,
  };

  return <MDBDataTable striped bordered small exportToCSV data={data} />;
}
