import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.p`
  display: block;
  margin: 25px auto;
  font-size: 1rem;
  text-align: center;
`;

const Complete = React.memo(function Complete(props) {
  const {
    location: { state },
  } = props;
  const { message } = state;

  return (
    <Wrapper>
      {message} <br /> If you have any questions, please reach out to us at{' '}
      <a href="tel:13102121026">310-212-1026</a>
    </Wrapper>
  );
});

Complete.propTypes = { message: PropTypes.string.isRequired };

export default withRouter(Complete);
