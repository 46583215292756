import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { MDBContainer, MDBBtn } from 'mdbreact';
import { jobStore } from './index';

const JobItem = ({ id, fileName, onClick }) => {
  return (
    <div className={''}>
      <Link to="#" onClick={onClick}>
        <span>Job Id: {id}, </span>
        <span>{fileName}</span>
      </Link>
    </div>
  );
};

function Jobs({ urlPath }) {
  const { state, api } = jobStore();
  const { getJobData } = api;
  const { jobs, error, errorMessage } = state;

  return (
    <MDBContainer>
      {jobs.map(({ id, fileName }) => (
        <div key={id}>
          <JobItem id={id} fileName={fileName} onClick={() => getJobData(id)} />
        </div>
      ))}
      {error && <h2 className="text-alert">{errorMessage}</h2>}
      <Link to={`/${urlPath}`}>
        <MDBBtn color="primary">Get CSV</MDBBtn>
      </Link>
    </MDBContainer>
  );
}

Jobs.propTypes = {
  urlPath: PropTypes.oneOf(['orders', 'customer-referral']),
};

JobItem.propTypes = {
  id: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default React.memo(Jobs);
