import Axios from 'axios';
import urls from 'Utils/urls';
import getEvents from 'Utils/requests/getEvents';
import getStores from 'Utils/requests/getStores';
import helper from './helper';

const { success } = require('Comps/sweetAlert');

const actions = {
  init: async (prevState, setState) => {
    try {
      const events = await getEvents();
      const stores = await getStores();

      const sortedEvent = events.sort((a, b) => a['stores._id'] - b['stores._id']);
      const storeEvent = helper.getUniqueStoreName(sortedEvent);
      const storeEventsInput = helper.convertToMdbSelectObj(storeEvent);
      storeEventsInput.unshift({ text: 'All', value: 'all' });
      const newStoreMdbInputs = helper.convertStoresInfoToMdbSelectObj(stores);

      setState({
        ...prevState,
        events,
        storeEventsInput,
        selectedEvents: events,
        newStoreMdbInputs,
      });
    } catch (err) {
      console.error(err);
      setState({ ...prevState, eventError: true });
    }
  },

  newEvent: async (prevState, setState, newEvent, e) => {
    e.preventDefault();
    try {
      const { status } = await Axios.post(`${urls.dbApi}/events/new-event`, { newEvent });
      if (status !== 200) return setState({ ...prevState, newEventErr: true });
      actions.init(prevState, setState);
      setState({ ...prevState, newEventErr: false });
      return success({ title: 'Success!', text: 'Store has been updated' });
    } catch (err) {
      console.error(err);
      const { status } = err.response;
      if (status === 500) return; // push to 500 page
      setState({ ...prevState, newEventErr: true });
    }
  },

  getSelectedStoreEvents: (prevState, setState, value) => {
    const { events } = prevState;
    let storeId;
    if (value[0] === 'all') {
      storeId = 'all';
    } else if (value[0] !== 'null') {
      storeId = parseInt(value[0], 10);
    } else {
      storeId = null;
    }
    if (storeId !== 'all') {
      const selectedEvents = events.filter((event) => {
        const eventStoreId = event['stores._id'];
        return storeId === eventStoreId;
      });
      setState({ ...prevState, selectedEvents });
    } else {
      setState({ ...prevState, selectedEvents: events });
    }
  },

  updateEventInfo: async (prevState, setState, newEventInfo, eventId, e) => {
    e.preventDefault();
    try {
      const { status } = await Axios.put(`${urls.dbApi}/events/update-event`, {
        data: { newEventInfo, eventId },
      });
      if (status === 200) {
        actions.init(prevState, setState);
        return success({ title: 'Success!', text: 'Store has been updated' });
      }
    } catch (err) {
      console.error(err);
    }
  },

  deleteEvent: async (prevState, setState, eventId, e) => {
    e.preventDefault();
    try {
      const { status } = await Axios.delete(`${urls.dbApi}/events/delete-event?eventId=${eventId}`);
      if (status === 200) {
        actions.init(prevState, setState);
        return success({ title: 'Success!', text: 'Store has been updated' });
      }
      setState({ ...prevState, eventError: true });
    } catch (err) {
      console.error(err);
      setState({ ...prevState, eventError: true });
    }
  },
};

export default actions;
