import Axios from 'axios';
import urls from 'Utils/urls';

const actions = {
  getProductInfo: async (setState, prevState, selectedBrandId, e) => {
    e.preventDefault();
    try {
      const products = await Axios.get(`${urls.bcApi}/products/get-inventory-report?selectedBrandId=${selectedBrandId}`);
      setState({
        ...prevState,
        inventoryProducts: products,
      });
    } catch (err) {
      console.error(err);
    }
  },
  getFileInfo: async (setState, prevState, file) => {
    try {
      const uploadedCsv = file[0];
      setState({ ...prevState, file: uploadedCsv });
    } catch (err) {
      console.error(err);
      setState({
        ...prevState,
        error: true,
      });
    }
  },

  uploadCsvFile: async (setState, state, e) => {
    e.preventDefault();
    const { file, selectedBrandId } = state;

    if (!file || file.length === 0) return setState({ ...state, error: true });
    const formData = new FormData();
    formData.append('csv', file);
    try {
      const data = await Axios.post(`${urls.otherApi}/csv/get-inventory-count`, formData, {
        params: { selectedBrandId },
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      // const csvFiles = await actions.getTempFiles();
      const updateChildSkus = data.data.map((row) => {
        row.child = row.child.join('; ');
        return row;
      });

      setState({ ...state, inventoryCountData: updateChildSkus, error: false });
    } catch (err) {
      console.error(err);
      const { status, message } = err.response.data;
      if (status === 400) {
        setState({ ...state, error: true, message });
      }
      setState({ ...state, status, message });
    }
  },
};

export default actions;
