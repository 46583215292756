const baseurl = process.env.LOCAL_DB ? 'http://localhost:3000' : '';

const urls = {
  dbApi: `${baseurl}/api/v1/cf-db`,
  otherApi: `${baseurl}/api/v1/other`,
  bcApi: `${baseurl}/api/v1/big-commerce`,
  googleGeocode: 'https://maps.googleapis.com/maps/api/geocode/json?address=',
};

export default urls;
