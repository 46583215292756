import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.p`
  font-size: 1rem;
  margin-top: 50px;
`;

export default React.memo(function TextDetails() {
  return (
    <Wrapper>
      We respect the privacy of your personal information. The information you are providing us will
      only be used to process your opt out of sale request or used to disclose to you what personal
      information we have collected, used, shared, or sold about you, and why we collected, used,
      shared, or sold that information. Please note that your request will apply to future sales of
      your personal information and will not impact sales made prior to your request.
    </Wrapper>
  );
});
