import _capitalize from 'lodash/capitalize';
import createInput from 'Comps/createInput';

const helper = {
  createNewEvent: (inputVals, eventHeaders) => {
    return inputVals.reduce((newEvent, val, i) => {
      Object.assign(newEvent, { [eventHeaders[i]]: newEvent });
      return newEvent;
    });
  },

  setSelectedStore: (eventCollection, index) => {
    return eventCollection[index];
  },

  getUniqueStoreName: (events) => {
    return events.reduce((mapObj, info, index) => {
      const name = info['stores.name'];
      const storeId = info['stores._id'];
      Object.assign(mapObj, { [name]: storeId });
      return mapObj;
    }, {});
  },

  convertToMdbSelectObj: (uniqueStore) => {
    return Object.keys(uniqueStore).map((storeName) => {
      const storeId = uniqueStore[storeName];
      if (storeName === 'null') {
        return { text: 'Third-party', value: `${storeId}` };
      }

      return { text: storeName, value: `${storeId}` };
    });
  },

  convertStoresInfoToMdbSelectObj: (stores) => {
    const inputCollection = stores.map((store) => {
      const storeId = store._id;
      const name = store.name;
      return { text: name, value: `${storeId}` };
    });
    inputCollection.unshift({ text: 'All', value: '' });
    return inputCollection;
  },

  // This will be the default for other input fields
  createInputCollection: (eventInputObj, options) => {
    const { name, type, required, className } = options || {};

    return eventInputObj.map(({ label, value, hint }, i) => {
      const [inputVal, input] = createInput({
        name: label + '-' + name,
        hint: _capitalize(hint),
        initialVal: value,
        type,
        required,
        label: _capitalize(name) + ' ' + label,
        className,
      });

      return [inputVal, input, label];
    });
  },
};

export default helper;
