import React from 'react';
import Error from './Error';
import Success from './Success';

const renderComponentBasedOnStatus = (status, message) => {
  if (status === 400 || status === 500) return <Error message={message} />;
  return <Success message={message} />;
};

function Components(props) {
  const { status, message } = props;
  return renderComponentBasedOnStatus(status, message);
}

/**
 *
 * @param {Object} props { status, message }
 * @returns Component Error or Warning
 */
export default function ResultHandlers(props) {
  return <Components {...props} />;
}
