import React from 'react';
import axios from 'axios';
import { MDBContainer, MDBSelect, MDBBtn, MDBRow, MDBCol, MDBDatePicker, MDBInput } from 'mdbreact';
import Spinner from '@Ui/Spinner';
import urls from 'Utils/urls';
import setToken from 'Utils/requests/setToken';
import { FREQUENCY_TYPE } from 'Shared/freqencyTypes';
import { DateRangePicker } from 'react-date-range';

import { JobProvider } from '../Jobs/index';
import Jobs from '../Jobs/Jobs';
import Progress from '../Progress';

import { CSV_TYPE, TIME_PERIOD } from '../../../../shared/fetchConstants';
import { Route, Link } from 'react-router-dom';

const tokenHeader = { headers: setToken('admin') };

const MONTHS = [
  TIME_PERIOD.JANUARY,
  TIME_PERIOD.FEBRUARY,
  TIME_PERIOD.MARCH,
  TIME_PERIOD.APRIL,
  TIME_PERIOD.MAY,
  TIME_PERIOD.JUNE,
  TIME_PERIOD.JULY,
  TIME_PERIOD.AUGUST,
  TIME_PERIOD.SEPTEMBER,
  TIME_PERIOD.OCTOBER,
  TIME_PERIOD.NOVEMBER,
  TIME_PERIOD.DECEMBER,
];

const QUARTERS = [
  TIME_PERIOD.JAN_TO_MARCH,
  TIME_PERIOD.APRIL_TO_JUNE,
  TIME_PERIOD.JULY_TO_SEPTEMBER,
  TIME_PERIOD.OCTOBER_TO_DECEMBER,
];

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validYears: [],
      frequencyType: FREQUENCY_TYPE.MONTHLY,
      frequencyOpts: [],
      timePeriod: TIME_PERIOD.JANUARY,
      timePeriodOpts: [],
      dailyDate: new Date(new Date().setHours(0, 0, 0, 0)).toString(),
      chosenYear: null,
      loading: true,
      downloading: false,
      errorMessage: '',
      jobs: [],
      dateCreated: true,
      selectionRange: {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
    };
  }

  componentDidMount = async () => {
    const url = `${urls.bcApi}/orders/oldestYear`;
    const {
      data: { oldestYear },
    } = await axios.get(url, tokenHeader);
    const currentYear = new Date().getFullYear();
    const validYears = [];
    for (let i = currentYear; i >= oldestYear; i--) {
      validYears.push(i);
    }

    const validYearOpts = validYears.map((year) => {
      return { text: year, value: year };
    });
    const frequencyOpts = Object.values(FREQUENCY_TYPE).map((freqType) => {
      return { value: freqType, text: freqType };
    });

    if (this.state.frequencyType === FREQUENCY_TYPE.MONTHLY) {
      this.setState({
        timePeriodOpts: MONTHS.map((timePeriod) => {
          return { value: timePeriod, text: timePeriod };
        }),
      });
    } else if (this.state.frequencyType === FREQUENCY_TYPE.QUARTERLY) {
      this.setState({
        timePeriodOpts: QUARTERS.map((timePeriod) => {
          return { value: timePeriod, text: timePeriod };
        }),
      });
    }

    this.setState({
      validYears: validYearOpts,
      chosenYear: currentYear,
      loading: false,
      frequencyOpts,
    });
  };

  componentDidCatch = (error) => {
    console.log(error);
    this.setState({
      loading: false,
      downloading: false,
      errorMessage: error.message || 'Unable to load component',
    });
  };

  dispatch = (action, ...args) => {
    return action(this.state, this.setState, ...args);
  };

  capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  handlePickerValue = (date) => {
    this.setState({ dailyDate: date.toString() });
  };

  onClickButtons = async (csvType, e) => {
    e.preventDefault();
    try {
      const {
        chosenYear,
        timePeriod,
        errorMessage,
        dailyDate,
        frequencyType,
        selectionRange,
        dateCreated,
      } = this.state;
      if (errorMessage) {
        this.setState({ errorMessage: '' });
      }
      let url;
      if (frequencyType === FREQUENCY_TYPE.CUSTOM) {
        url = `${urls.bcApi}/orders/csv/${dateCreated}/${csvType}/time-period/${frequencyType}/${selectionRange.startDate}/${selectionRange.endDate}`;
      } else {
        url =
          frequencyType === FREQUENCY_TYPE.DAILY
            ? `${urls.bcApi}/orders/csv/${dateCreated}/${csvType}/date/${dailyDate}`
            : `${urls.bcApi}/orders/csv/${dateCreated}/${csvType}/time-period/${timePeriod}/year/${chosenYear}`;
      }
      this.setState({ downloading: true });
      const { data } = await axios.get(url, tokenHeader);
      if (!data) {
        this.setState({ errorMessage: 'No orders found for the selected time period' });
      } else {
        this.setState({ jobs: [...this.state.jobs, { id: data.id, progress: 25 }] });
      }
    } catch (error) {
      this.setState({
        errorMessage:
          error.response.data.message ||
          'Something went wrong in the download process. Please try again or check your server',
      });
    } finally {
      const { dateCreated } = this.state;
      if (!dateCreated) {
        this.setState({ downloading: false, dateCreated: false });
      } else {
        this.setState({ downloading: false });
      }
    }
  };

  getByCreatedDate = () => {
    console.log(this.state.dateCreated);
    console.log('hello');
    this.setState({ dateCreated: !this.state.dateCreated });
  };

  selectDateRange = (ranges) => {
    const { startDate, endDate, key } = ranges.selection;
    this.setState({ selectionRange: { startDate, endDate, key } });
  };

  render() {
    const {
      frequencyType,
      frequencyOpts,
      validYears,
      loading,
      downloading,
      errorMessage,
      chosenYear,
      timePeriod,
      timePeriodOpts,
      dailyDate,
      jobs,
      selectionRange,
      dateCreated,
    } = this.state;
    const loadingOrDownloading = <Spinner />;

    // const selectionRange = {
    //   startDate: new Date(),
    //   endDate: new Date(),
    //   key: 'selection',
    // };
    return (
      <MDBContainer>
        <h1>Download Headers Or Details CSV</h1>
        <Route exact path="/orders">
          {!!errorMessage && <h3 style={{ color: 'red' }}>{errorMessage}</h3>}
          {loading || downloading ? (
            loadingOrDownloading
          ) : (
            <>
              <MDBRow>
                <MDBCol size="12" sm="3" className="mt-5">
                  <MDBInput
                    type="checkbox"
                    id="dateCreatedCheck"
                    onChange={this.getByCreatedDate}
                    checked={!this.state.dateCreated}
                    label="Get by Modified Date"
                    containerClass="p-0"
                  />
                  <MDBSelect
                    options={frequencyOpts}
                    getValue={(value) => {
                      const [chosenFrequencyType] = value;
                      let resettedTimePeriod;
                      if (chosenFrequencyType === FREQUENCY_TYPE.ANNUALLY) {
                        resettedTimePeriod = TIME_PERIOD.ANNUAL;
                      } else if (chosenFrequencyType === FREQUENCY_TYPE.MONTHLY) {
                        resettedTimePeriod = MONTHS[0];
                        this.setState({
                          timePeriodOpts: MONTHS.map((time) => {
                            return { value: time, text: time };
                          }),
                        });
                      } else {
                        resettedTimePeriod = QUARTERS[0];
                        this.setState({
                          timePeriodOpts: QUARTERS.map((time) => {
                            return { value: time, text: time };
                          }),
                        });
                      }

                      this.setState({
                        frequencyType: chosenFrequencyType,
                        timePeriod: resettedTimePeriod,
                      });
                    }}
                    label="Frequency"
                    value={frequencyType}
                    selected="Choose frequency"
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol size="12" sm="4">
                  {frequencyType !== FREQUENCY_TYPE.CUSTOM && (
                    <MDBSelect
                      options={validYears}
                      label="Year"
                      selected="Choose year"
                      getValue={(value) => this.setState({ chosenYear: value[0] })}
                      value={chosenYear}
                    />
                  )}
                  {/* </select> */}
                  {frequencyType !== FREQUENCY_TYPE.ANNUALLY &&
                    frequencyType !== FREQUENCY_TYPE.DAILY &&
                    frequencyType !== FREQUENCY_TYPE.CUSTOM && (
                      <MDBSelect
                        options={timePeriodOpts}
                        label={'Time Period'}
                        selected="Choose Time Period"
                        value={timePeriod}
                        getValue={([value]) =>
                          this.setState({
                            timePeriod: value,
                          })
                        }
                      />
                    )}
                  {frequencyType === FREQUENCY_TYPE.DAILY && (
                    <MDBDatePicker
                      valueDefault={dailyDate}
                      disableFuture
                      minDate={`${validYears[validYears.length - 1]}-01-01`}
                      getValue={this.handlePickerValue}
                    />
                  )}
                  {frequencyType === FREQUENCY_TYPE.CUSTOM && (
                    <DateRangePicker ranges={[selectionRange]} onChange={this.selectDateRange} />
                  )}
                </MDBCol>
                <MDBCol size="12">
                  <MDBBtn
                    color={'primary'}
                    onClick={(e) => this.onClickButtons(CSV_TYPE.HEADERS, e)}
                    name="headers-csv-download">
                    Headers CSV
                  </MDBBtn>
                  <MDBBtn
                    color={'secondary'}
                    onClick={(e) => this.onClickButtons(CSV_TYPE.DETAILS, e)}
                    name="details-csv-download">
                    Details CSV
                  </MDBBtn>
                  <Link to="/orders/jobs">
                    <MDBBtn>View completed csvs</MDBBtn>
                  </Link>
                </MDBCol>
              </MDBRow>
              {jobs.length > 0 && (
                <MDBRow className="mt-5">
                  <MDBCol>
                    <h2>Download in Progress</h2>
                    {jobs.map(({ id, progress }) => (
                      <Progress key={id} id={id} urlPath="orders" />
                    ))}
                  </MDBCol>
                </MDBRow>
              )}
            </>
          )}
        </Route>

        <Route exact path="/orders/jobs">
          <JobProvider urlPath="orders">
            <Jobs urlPath="orders" />
          </JobProvider>
        </Route>
      </MDBContainer>
    );
  }
}

export default Main;
