import React, { useState, useEffect } from 'react';
import { MDBCol, MDBSelect } from 'mdbreact';
import actions from './actions';
import initialState from './state';
import getBrandsFromBc from './getBrandsFromBc';

const convertToMdbSelectObj = (brands) => {
  return brands.sort().map((brand) => {
    return { text: `${brand.name} - ${brand.id} `, value: brand.id };
  });
};

export default function createSelectBrand(props) {
  const [state, setState] = useState(initialState);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const brandsInfo = await getBrandsFromBc();
  //       const convertedForMdbSelect = convertToMdbSelectObj(brandsInfo.data);
  //       convertedForMdbSelect.unshift({ text: 'All - 0', value: 0 });
  //       setState({ ...state, brands: convertedForMdbSelect });
  //     } catch (err) {
  //       console.error(err);
  //       setState({ error: true });
  //     }
  //   })();
  // }, []);

  const dispatch = (action, ...args) => {
    return action(setState, state, ...args);
  };

  const selectInput =
    state.brands.length > 0 ? (
      <MDBCol size="4">
        <MDBSelect
          getValue={(brandId) => dispatch(actions.selectBrand, brandId)}
          options={state.brands}
          label="All Brands"
          search={true}
          selected="Select a brand"
        />
      </MDBCol>
    ) : null;
  const error = <div>Cannot make request to BigCOmmerce</div>;

  return [state.selectedBrandId, selectInput];
}
