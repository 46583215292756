import React from 'react';

export default function ImgUploadInfo(props) {
  return (
    <small>
      Please upload image onto our Amazon AWS S3 bucket. <br /> Once done, please copy and paste
      link. <br />
      <strong>Image width needs to be 1366 upon upload to AWS S3.</strong>
    </small>
  );
}
