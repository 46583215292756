import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import { Helmet } from 'react-helmet';
import Header from '@Ui/Header';
import getPhotoSubmissions from 'Utils/requests/getPhotoSubmissions';
import Components from './Components/Components';
import initialState from './state';
import actions from './actions';

export default function HolidayPhotos(props) {
  const history = useHistory();

  const [state, setState] = useState(initialState);
  const [photoSubmissions, setSubmissions] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const data = await getPhotoSubmissions();
        setSubmissions(data);
      } catch (err) {
        console.log(err);
        // useHistory.push('')
      }
    })();
  }, []);

  return (
    <MDBContainer className="holiday-photo-upload">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Photo Submissions</title>
      </Helmet>
      <Header headerTag="h1" text={'Holiday Photos'} />
      <Components actions={actions} state={state} photoSubmissions={photoSubmissions} />
    </MDBContainer>
  );
}
