import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './dropdown.module.scss';

const propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  options: PropTypes.array.isRequired,
  constraints: PropTypes.object,
  register: PropTypes.func,
};

const Dropdown = React.forwardRef(function Dropdown(
  { id, label, name, required, errors, options, register, constraints },
  ref,
) {
  return (
    <div className="select-wrapper md-form">
      <select
        className={styles.select}
        ref={register && register(constraints)}
        name={name}
        id={id}
        required={required}>
        {options.map(({ value, text }, i) => (
          <option key={i} value={value}>
            {text}
          </option>
        ))}
      </select>

      <label aria-labelledby={label} className={classNames('active', styles.label)} htmlFor={id}>
        {label}
      </label>
      {errors && errors[name] && <div className={styles.error}>{errors[name].message}</div>}
    </div>
  );
});

Dropdown.propTypes = propTypes;

export default Dropdown;
