const initialState = {
  file: [],
  fileError: false,
  newCsvData: '',
  dataForTable: [],
  imgLinkError: [],
  tempCsvFiles: [],
  customFields: false,
  tempCsvError: false,
  error: false,
  submissionType: 'image',
  message: '',
  brands: [],
  selectedBrandId: 0,
  brandsError: false,
  wasabiLinksCreated: false,
};

export default initialState;
