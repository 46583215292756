const helper = {
  /**
   * @param {Object[]} stores
   * @return {Object[]} mdbOptions
   * @example [{ text: 'storeName', value: storeIndex }]
   */
  convertToMDBSelectCol: (stores) => {
    return stores.map((store, i) => {
      const temp = {};
      Object.assign(temp, { text: store.name, value: `${i}` });
      return temp;
    });
  },
};

export default helper;
