import React, { useState, useEffect, createContext, useContext } from 'react';
import produce from 'immer';
import Axios from 'axios';
import urls from 'Utils/urls';
import setToken from 'Utils/requests/setToken';
import isEmpty from 'lodash/isEmpty';

const tokenHeader = { headers: setToken('admin') };

const CustomerReferralContext = createContext();

const initialState = {
  jobs: [],
  warningMessage: '',
  selectionRange: { startDate: new Date(), endDate: new Date(), key: 'selection' },
  error: false,
  errorMessage: '',
};

function storeApi(state, setState) {
  const getFile = async () => {
    try {
      const { selectionRange } = state;
      const { startDate, endDate } = selectionRange;
      const { jobs } = state;

      const { data } = await Axios.get(
        `${urls.dbApi}/customer-referral/get-customers/${startDate}/${endDate}`,
        tokenHeader,
      );
      if (isEmpty(data)) {
        setState(
          produce((draft) => {
            draft.warningMessage =
              'No customer referrals are found during the selected time period';
          }),
        );
      }

      const id = data;

      setState(
        produce((draft) => {
          draft.jobs = [...jobs, { id, progress: 25 }];
        }),
      );

      console.log({ data });
    } catch (err) {
      console.error(err);
      const { status } = err.response;
      const { message } = err.response.data;
      setState(
        produce((draft) => {
          draft.error = true;
          draft.ErrorMessage =
            message ||
            `Status: ${status}. Something went wrong when trying to download customer information. Please try again or check your server.`;
        }),
      );
    }
  };
  const selectDateRange = (ranges) => {
    const { startDate, endDate, key } = ranges.selection;
    const selectionRange = { startDate, endDate, key };
    setState(
      produce((draft) => {
        draft.selectionRange = selectionRange;
      }),
    );
  };

  return { getFile, selectDateRange };
}

function CustomerReferralProvider({ children }) {
  const [state, setState] = useState(initialState);
  const api = storeApi(state, setState);
  const value = { api, state };

  return (
    <CustomerReferralContext.Provider value={value}>{children}</CustomerReferralContext.Provider>
  );
}

const useCustomerReferralStore = () => useContext(CustomerReferralContext);

export { CustomerReferralProvider, useCustomerReferralStore };
