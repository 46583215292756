import React from 'react';
import { MDBBtn, MDBRow, MDBCol } from 'mdbreact';
import createInput from '../../../../Components/createInput';

import createInputCollection from 'Utils/createInputCollection';

export default function StoreHours(props) {
  const { storeHours, dispatch, actions, storeId, storeIndex } = props;

  const hoursInputs = createInputCollection(storeHours, createInput, {
    name: '',
    hint: 'Store hours',
  });
  const hoursVal = hoursInputs.map(([hour]) => hour);

  return (
    <section className="stores__hours">
      <h2 className="header2">Store Hours</h2>

      <MDBRow>
        {hoursInputs.map(([hour, input], i) => (
          <MDBCol key={i} size="12" sm="4">
            {input}
          </MDBCol>
        ))}
      </MDBRow>

      <MDBBtn
        className="mb-4"
        color="primary"
        size="sm"
        onClick={(e) =>
          dispatch(actions.updateStoreInformation, { hoursVal, storeId, storeIndex }, e)
        }>
        Update Hours
      </MDBBtn>
    </section>
  );
}
