import Axios from 'axios';
import urls from 'Utils/urls';

const { success } = require('../../Components/sweetAlert');

const actions = {
  /**
   * @param {Object} prevState
   * @param {Function} setState
   */
  addStore: (prevState, setState, options, e) => {},

  /**
   * set selected store in state.
   * @param {Object} prevState
   * @param {Function} setState
   * @param {String} selectedVal
   */
  chooseStore: (prevState, setState, inputVal) => {
    const selectedVal = inputVal.length < 1 ? 0 : [inputVal];
    const selectedStore = prevState.stores[selectedVal];
    selectedStore.storeIndex = selectedVal;
    setState({
      ...prevState,
      currentStore: selectedStore,
    });
  },

  /**
   * @param {Object} prevState
   * @param {Function} setState
   * @param {Number} options.storeId
   * @param {Number} options.storeIndex
   * @param {Object[{}]} options.hoursVal
   * @param {Object[{}]} options.servicesVal
   * @param {String} options.serviceType
   * @param {Object[{}]} options.basicInfo
   */
  updateStoreInformation: async (prevState, setState, options, e) => {
    try {
      const { storeId, storeIndex, servicesVal, serviceType, hoursVal, basicInfo } = options || {};

      const { status } = await Axios.put(`${urls.dbApi}/stores/update-store`, {
        data: { hoursVal, storeId, servicesVal, serviceType, basicInfo },
      });

      success({ title: 'Success!', text: 'Store has been updated' });
    } catch (err) {
      console.error(err);
      const {
        status,
        data: { message },
      } = err.response || {};

      if (status === 500) return; // push to 500 error
      setState({ ...prevState, updateError: true });
    }
  },

  /**
   * @param {Object} prevState
   * @param {Function} setState
   */
  deleteStore: (prevState, setState, storeId, e) => {},
};

export default actions;
