import Swal from 'sweetalert2';

/**
 *
 * @param {String} param0 title
 * @param {String} param1 text
 * @param {String} param2 button
 */
const success = ({ title, text, button }) => {
  const sweetAlertWithoutBtn = {
    type: 'success',
    title: `${title}`,
    text: `${text}`,
    timer: 3000,
    timerProgressBar: true,
    showConfirmButton: false,
  };

  const sweetAlertWithBtn = {
    type: 'success',
    title: `${title}`,
    text: `${text}`,
    showConfirmButton: true,
    confirmButtonText: `${button}`,
  };
  return button ? Swal.fire(sweetAlertWithBtn) : Swal.fire(sweetAlertWithoutBtn);
};

/**
 *
 * @param {String} param0 title
 * @param {String} param1 text
 * @param {String} param2 button
 */
const error = ({ title, text, button }) => {
  const sweetAlertWithoutBtn = {
    type: 'error',
    title: `${title}`,
    text: `${text}`,
    timer: 3000,
    timerProgressBar: true,
    showConfirmButton: false,
  };

  const sweetAlertWithBtn = {
    type: 'error',
    title: `${title}`,
    text: `${text}`,
    showConfirmButton: true,
    confirmButtonText: `${button}`,
  };
  return button ? Swal.fire(sweetAlertWithBtn) : Swal.fire(sweetAlertWithoutBtn);
};

/**
 *
 * @param {String} param0 title
 * @param {String} param1 text
 * @param {String} param2 button
 */
const warning = ({ title, text, button }) => {
  const sweetAlertWithoutBtn = {
    type: 'warning',
    title: `${title}`,
    text: `${text}`,
    timer: 3000,
    timerProgressBar: true,
    showConfirmButton: false,
  };

  const sweetAlertWithBtn = {
    type: 'warning',
    title: `${title}`,
    text: `${text}`,
    showConfirmButton: true,
    confirmButtonText: `${button}`,
  };
  return button ? Swal.fire(sweetAlertWithBtn) : Swal.fire(sweetAlertWithoutBtn);
};

/**
 *
 * @param {String} options.text
 * @param {String} options.title
 */
const confirmDelete = (options) => {
  const { text, title } = options || {};
  return new Promise(async (resolve, reject) => {
    try {
      const result = await Swal.fire({
        title: title || 'Are you sure?',
        text:
          text ||
          'Once you delete the trainer, you will not be able to edit this same trainer again',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete trainer.',
      });

      resolve(result.value);
    } catch (err) {
      console.error(err);
      reject(err);
    }
  });
};

module.exports = { success, error, warning, confirmDelete };
