import React, { useState } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdbreact';
import Tabs from 'Comps/Tabs';
import Switch from 'Comps/Switch';
import Steps from '../../Steps';
import CsvUpload from './csv/CsvUpload';
import InventoryReports from '../../InventoryReports';
import instructions from './instructions.jsx';
import { CSVDownload } from 'react-csv';

import createInput from 'Comps/createInput';
import Header from '@Ui/Header';

const inputField = () => {
  const [values, input] = createInput({
    hint: 'Enter csv columns to be modified.',
    icon: 'file-csv',
    id: 'csv-input',
  });
  return [values, input];
};

export default function Components(props) {
  const [createWasabiLinks, setState] = useState(false);

  const getSwitchVal = (value) => {
    setState(value);
  };

  const bcConversionBody = (
    <>
      <Steps
        steps={instructions}
        body={[
          '',
          <>
            <Switch key="switch1" getValue={getSwitchVal} />
            <CsvUpload createWasabiLinks={createWasabiLinks} key={'Steps for Upload'} {...props} />
          </>,
        ]}
        header="inventory upload steps"
        key={'steps information'}
      />
    </>
  );

  return (
    <MDBContainer>
      {/* <MDBRow>
        <MDBCol className="mt-4" size="12">
          {tempCsvFiles.length > 0 ? <CsvCollection {...props} /> : null}
          {Object.keys(imgLinkError).length > 0 ? (
            <ErrorLinksForWasabi imgLinkError={imgLinkError} />
          ) : null}
        </MDBCol>
      </MDBRow> */}
      <Header headerTag="h1" text={'Big-Commerce file reformat'} />
      {/* <CSVDownload data={state.newCsvData} /> */}
      <Tabs
        headers={['BigCommerce Conversion', 'Inventory Report']}
        bodies={[bcConversionBody, <InventoryReports key={'inventory-report'} />]}
      />
    </MDBContainer>
  );
}
