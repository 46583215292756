import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: block;
  margin-top: 25px;
  padding-top: 35px;
  border-top: thin solid rgba(0, 0, 0, 0.12);
`;

const CcpaFooter = React.memo(function CcpaFooter() {
  return (
    <Wrapper>
      <a href="https://centinelafeed.com/privacy/">Privacy Policy</a> {' | '}
      <a href="https://centinelafeed.com/feedback/">Feedback</a>
    </Wrapper>
  );
});

export default CcpaFooter;
