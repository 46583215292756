import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MDBProgress } from 'mdbreact';
import Axios from 'axios';
import urls from 'Utils/urls';
import setToken from 'Utils/requests/setToken';

const tokenHeader = { headers: setToken('admin') };

/**
 * @param {String || Number} param0 id
 * @param {String} param1 urlPath
 * urlPath: orders or customer-referral
 * Current urlPath is not dynamic. If something changes,
 * Will need to manually update this.
 */

const Progress = React.memo(function Progress({ id, urlPath }) {
  const [currentProgress, updateProgress] = useState(0);

  const getJobProgress = async (progress, setProgress, jobId) => {
    function sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }
    try {
      const apiPath = urlPath === 'orders' ? urls.bcApi : urls.dbApi;
      let complete = false;
      while (!complete) {
        // eslint-disable-next-line no-await-in-loop
        await sleep(3000);
        // eslint-disable-next-line no-await-in-loop
        const { data } = await Axios.get(
          `${apiPath}/${urlPath}/get-job-progress/${jobId}`,
          tokenHeader,
        );
        if (data === 100) {
          setProgress(data);
          complete = true;
        } else if (parseInt(progress, 10) < 75) {
          setProgress(progress + 15);
        }
      }
    } catch (error) {
      console.error(error);
      error({ title: 'Error', text: error.response.data.message || 'Job failed.' });
    }
  };

  useEffect(() => {
    getJobProgress(currentProgress, updateProgress, id);
  }, [currentProgress]);

  return (
    <>
      <span>job {id}:</span> <MDBProgress material value={currentProgress} animated />
      {currentProgress === 100 && <div className="text-success">Completed!</div>}
    </>
  );
});

Progress.propTypes = { id: PropTypes.number.isRequired };

export default Progress;
