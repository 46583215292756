import React, { Fragment } from 'react';
import { MDBFileInput } from 'mdbreact';
import Error from 'Comps/ResultHandlers';

const message = 'Please upload a file before submitting.';

export default function FileUpload(props) {
  const { dispatch, actions, state } = props;

  return (
    <Fragment>
      <MDBFileInput required getValue={(fileName) => dispatch(actions.getFileInfo, fileName)} />
      {state.fileError ? <Error message={message} /> : null}
    </Fragment>
  );
}
