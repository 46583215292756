import React, { Component } from 'react';
import { MDBSideNavNav, MDBSideNav, MDBSideNavLink, MDBContainer, MDBIcon } from 'mdbreact';

import logo from '../../imgs/color_logo.png';

export default class SideNavPage extends Component {
  state = {
    sideNavLeft: false,
  };

  sidenavToggle = (sidenavId) => () => {
    const sidenavNr = `sideNav${sidenavId}`;
    this.setState({
      [sidenavNr]: !this.state[sidenavNr],
    });
  };

  render() {
    return (
      <header>
        <MDBContainer>
          <MDBSideNav
            slim
            fixed
            mask="rgba-red-strong"
            triggerOpening={this.state.sideNavLeft}
            breakWidth={1300}
            className="sn-bg-1">
            <li>
              <div className="logo-wrapper sn-ad-avatar-wrapper">
                <a href="#!">
                  <img alt="Centinela Feed logo" src={logo} className="rounded-circle" />
                  <span className="text-uppercase">Centinela Feed</span>
                </a>
              </div>
            </li>

            <MDBSideNavNav>
              <MDBSideNavLink to="/stores" topLevel>
                <MDBIcon icon="store" className="mr-2" />
                Stores
              </MDBSideNavLink>
              <MDBSideNavLink to="/trainers" topLevel>
                <MDBIcon icon="user-alt" className="mr-2" />
                Trainers
              </MDBSideNavLink>
              <MDBSideNavLink to="/events" topLevel>
                <MDBIcon icon="calendar-check" className="mr-2" />
                Events
              </MDBSideNavLink>
              <MDBSideNavLink to="/csv-upload" topLevel>
                <MDBIcon icon="file-csv" className="mr-2" />
                BigCommerce CSV Edit
              </MDBSideNavLink>
              <MDBSideNavLink to="/holiay-photo-upload" topLevel>
                <MDBIcon icon="images" className="mr-2" />
                Holiday Photos Upload
              </MDBSideNavLink>
              <MDBSideNavLink to="/orders" topLevel>
                <MDBIcon icon="file-excel" className="mr-2" />
                Orders
              </MDBSideNavLink>
              <MDBSideNavLink to="/customer-referral" topLevel>
                <MDBIcon icon="mail-bulk" className="mr-2" />
                Customer Refereral
              </MDBSideNavLink>
            </MDBSideNavNav>
          </MDBSideNav>
        </MDBContainer>
      </header>
    );
  }
}
