import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBFileInput } from 'mdbreact';
import InventoryTable from './inventoryTable';
import Error from 'Comps/ResultHandlers';

const message = 'Please upload a file before submitting.';

import styles from '../styles/InventoryReports.module.scss';

export default function Components(props) {
  const { dispatch, actions, state } = props;

  return (
    <MDBContainer>
      <MDBRow>
        <MDBCol size="12">
          <MDBFileInput required getValue={(fileName) => dispatch(actions.getFileInfo, fileName)} />
          {state.error ? <Error message={message} /> : null}
        </MDBCol>
        <MDBCol size="12" md="5">
          <MDBBtn onClick={(e) => dispatch(actions.uploadCsvFile, e)}>Get Inventory Count</MDBBtn>
        </MDBCol>
        <MDBCol size="12">
          <InventoryTable inventoryCountData={state.inventoryCountData} />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}
