import React from 'react';
import { Route } from 'react-router-dom';

import styles from './index.module.scss';

export default function Footer(props) {
  const getTestString = () => {
    const el = document.querySelector('.test');
  };

  return (
    <Route path="/">
      <footer className={styles.footer}>
        <div className="text-center">
          &copy; Copyright {new Date().getFullYear()} Centinela Feed
        </div>
      </footer>
    </Route>
  );
}
