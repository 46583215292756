export default {
  swapFormActive: (a, setState) => (param) => (e) => {
    setState({
      formActivePanel: param,
      ['formActivePanel' + a + 'Changed']: true,
    });
  },

  handleNextPrevClick: (a, setState) => (param) => (e) => {
    setState({
      ['formActivePanel' + a]: param,
      ['formActivePanel' + a + 'Changed']: true,
    });
  },

  calculateAutofocus: (a, state) => {
    if (state['formActivePanel' + a + 'Changed']) {
      return true;
    }
  },
};
