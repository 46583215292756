import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import NewSubmission from './newSubmission';
import Modals from 'Comps/Modals';
import PhotoSubmissions from './photoSubmissions';
import EditSubmission from './editSubmission';

const Components = React.memo(function Components({ actions, state, photoSubmissions }) {
  const { newUpload } = state;
  const [isOpen, toggle] = useState(false);

  return (
    <>
      <Modals
        header={'New Photo Submission'}
        body={<NewSubmission actions={actions} />}
        buttonText={'New PhotoSubmission'}
        isOpen={isOpen}
        toggle={toggle}
        size={'lg'}
      />
      <PhotoSubmissions photoSubmissions={photoSubmissions} actions={actions} />
      <Route path="/holiay-photo-upload/edit/:id">
        <EditSubmission />
      </Route>
    </>
  );
});

Components.propTypes = {
  actions: PropTypes.object,
  state: PropTypes.object,
  photoSubmissions: PropTypes.array.isRequired,
};

export default Components;
