import Axios from 'axios';
import urls from 'Utils/urls';
import helper from './helper';
import initialState from './state';
import fileDownload from 'js-file-download';
import swal from 'sweetalert2';
import moment from 'moment';

const { success, error, warning } = require('Comps/sweetAlert');

const { convertHeaderInputsToArr } = helper;

const actions = {
  getFileInfo: async (setState, prevState, file) => {
    try {
      const uploadedCsv = file[0];

      setState({ ...prevState, file: uploadedCsv });
    } catch (err) {
      console.error(err);
      setState({
        ...prevState,
        error: true,
      });
    }
  },

  getTempFiles: async () => {
    try {
      const data = await Axios.get(`${urls.otherApi}/csv/get-csvs`);

      return data.data;
    } catch (err) {
      console.error(err);
      const { status } = err.response;
      if (status === 500) return; // push to 500
    }
  },

  uploadCsvFile: async (setState, state, createWasabiLinks, e) => {
    e.preventDefault();
    const { file, selectedBrandId } = state;

    if (!file || file.length === 0) return setState({ ...state, fileError: true });
    const formData = new FormData();
    formData.append('csv', file);
    try {
      swal.fire({
        title: 'Uploading',
        text: 'Please wait while information are processed.',
        onBeforeOpen: async () => {
          try {
            swal.showLoading();
            const data = await Axios.post(`${urls.otherApi}/csv/upload-csv-file`, formData, {
              params: { selectedBrandId, createWasabiLinks },
              headers: { 'Content-Type': 'multipart/form-data' },
            });

            const csvFiles = await actions.getTempFiles();

            setState({ ...state, tempCsvFiles: csvFiles, tempCsvError: false });
            success({
              title: 'Success!',
              text: 'CSV Files updated with BigCommerce info',
              button: 'Okay',
            });
            fileDownload(data.data.dlFile, moment().format('l') + '.csv');
            fileDownload(
              data.data.unusedInventory,
              'unused_items_' + moment().format('l') + '.csv',
            );
            setState(initialState);
          } catch (err) {
            console.error(err);
            swal.close();
            error({
              title: 'Error!',
              text: 'Please check CSV files and SKUs',
              button: 'Close',
            });
          }
        },
      });
    } catch (err) {
      console.error(err);
      const { status, message } = err.response.data;
      if (status === 400) {
        setState({ ...state, error: true, message });
      }
      setState({ ...state, status, message });
      swal.close();
      swal.fire({ title: 'Error!', text: 'Please check CSV files and SKUs', button: 'Close' });
    }
  },

  saveImgLinksToWasabi: async (setState, state, e) => {
    e.preventDefault();
    const { file } = state;
    const formData = new FormData();
    formData.append('csv', file);
    try {
      swal.fire({
        title: 'Uploading',
        text: 'Please wait while images are being processed.',
        onBeforeOpen: async () => {
          swal.showLoading();
          const {
            data: { errorLinks },
          } = await Axios.post(`${urls.otherApi}/csv/upload-images-to-wasabi`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
          console.log({ errorLinks });
          const csvFiles = await actions.getTempFiles(setState, state);

          setState({
            ...state,
            tempCsvFiles: csvFiles,
            imgLinkError: errorLinks,
            wasabiLinksCreated: true,
            tempCsvError: false,
          });
          success({ title: 'Success!', text: 'Images uploaded onto Wasabi', button: 'Okay' });
        },
      });
    } catch (err) {
      swal.close();
      swal.fire({ title: 'Error!', text: 'Please check csv file column names', button: 'Close' });
      console.error(err);
    }
  },

  downloadCsvFiles: async (setState, state, fileName, e) => {
    e.preventDefault();
    try {
      const data = await Axios.post(`${urls.otherApi}/csv/download-csv-file`, {
        query: { fileName },
      });
      fileDownload(data.data, fileName);
    } catch (err) {
      console.error(err);
    }
  },

  useCustomFields: (setState, prevState) => {
    setState({
      ...prevState,
      customFields: !prevState.customFields,
    });
  },

  updateSubmission: (setState, state, e) => {
    e.preventDefault();
    if (state.submissionType === 'image') {
      setState({ ...state, submissionType: 'bcCsvFile' });
    } else {
      setState({ ...state, submissionType: 'image' });
    }
  },
};

export default actions;
