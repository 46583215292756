import React from 'react';
import { MDBDataTable } from 'mdbreact';

export default function ErrorLinksForWasabi(props) {
  const { imgLinkError } = props;
  const data = {
    columns: [
      {
        label: 'Product Sku',
        field: 'productSku',
      },
      {
        label: 'Images with inaccessible links',
        field: 'imageColumn',
      },
      {
        label: 'Url link',
        field: 'urlLink',
      },
    ],
    rows: [],
  };
  data.rows = imgLinkError.reduce((imgArrLinks, imgLinkRow) => {
    imgLinkRow.forEach((imgLinksObj) => {
      const temp = {};
      if (imgLinksObj !== null) {
        const [productSku, imageColumn, urlLink] = imgLinksObj;
        Object.assign(temp, { productSku, imageColumn, urlLink });
      }
      imgArrLinks.push(temp);
    });
    return imgArrLinks;
  }, []);

  return (
    <section className="error-links">
      <h2>Non-image Links</h2>
      <MDBDataTable striped bordered small data={data} />
    </section>
  );
}
