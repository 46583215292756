import React from 'react';
import { MDBAnimation } from 'mdbreact';

/**
 * return success message
 * @param {String} message
 */
export default function Error({ message }) {
  return (
    <MDBAnimation type="shake" duration="800ms">
      <div className="text-green font-weight-bold">{message}</div>
    </MDBAnimation>
  );
}
