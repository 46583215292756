import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import InputField from 'Comps/InputField';
import postPassword from 'Utils/requests/postPassword';
import { MDBBtn } from 'mdbreact';
import Header from '@Ui/Header';
import styles from './login.module.scss';

export default function Login() {
  const [error, setErr] = useState(false);
  const [errMessage, setErrMsg] = useState('');
  const { register, handleSubmit, errors } = useForm();

  const submit = async ({ password }) => {
    try {
      const { data } = await postPassword(password);
      const { token } = data;
      localStorage.setItem('admin', JSON.stringify({ token }));
      window.location.reload();
    } catch (err) {
      setErr(true);
      setErrMsg(err.response.data.message);
    }
  };

  return (
    <div className={styles.login}>
      <Header headerTag="h1" text="Centinela Feed & Pet Supplies" />
      <span>Enter password to Login</span>
      <form onSubmit={handleSubmit(submit)} className={styles.form}>
        <InputField
          label="password"
          id="password"
          constraints={{
            required: { value: true, message: 'Please enter password' },
            shouldFocusError: true,
          }}
          register={register}
          errors={errors}
          formFor={'password'}
          type="password"
        />
        {error && <div className="error">{errMessage}</div>}
        <MDBBtn size="sm" type={'submit'}>
          Submit
        </MDBBtn>
      </form>
    </div>
  );
}
