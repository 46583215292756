import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './header.module.scss';

const Header = React.memo(function Header({ headerTag, text }) {
  const Tag = headerTag;
  return <Tag className={classNames(styles[`header${headerTag}`])}>{text}</Tag>;
});

Header.propTypes = {
  header: PropTypes.string,
  text: PropTypes.string,
};

export default Header;
