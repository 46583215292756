import Axios from 'axios';
import urls from 'Utils/urls';
import setToken from './setToken';

const tokenHeader = { headers: setToken('admin') };

export default function getPhotoSubmissions() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await Axios.get(`${urls.dbApi}/photo-submissions/get-all-submissions`, tokenHeader);
      resolve(data);
    } catch (err) {
      console.error(err);
      reject(err);
    }
  });
}
