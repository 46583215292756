import Axios from 'axios';
import urls from 'Utils/urls';
import setToken from 'Utils/requests/setToken';

const tokenHeader = { headers: setToken('admin') };

const getEvents = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await Axios.get(`${urls.dbApi}/events/get-events`, tokenHeader);
      resolve(data);
    } catch (err) {
      console.error(err);
      reject(err);
    }
  });
};

export default getEvents;
