import React from 'react';
import Header from '@Ui/Header';
import styles from './FourOhFour.module.scss';

export default function FourOhFour(props) {
  return (
    <div className={`${styles.fourOhFour}`}>
      <Header headerTag="h1" text={`Error 400: Bad Request`} />
    </div>
  );
}
