import React, { useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { MDBBtn, MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import InputField from 'Comps/InputField/index';
import MaskedFieldInput from 'Comps/InputField/maskedInputField';
import Dropdown from 'Comps/Dropdown/dropdown';
import Errors from 'Comps/ResultHandlers/Error';
import ReCAPTCHA from 'react-google-recaptcha';
import stateOptions from 'Utils/stateOptions';
import urls from 'Utils/urls';
import message from '../message';
import nameFields from '../nameFields';
import styles from '../ccpa.module.scss';

const Form = React.memo(function Form({ requestType, requestInfo }) {
  const { register, handleSubmit, errors, control } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [userIsNotBot, setIsNotBot] = useState(false);

  const history = useHistory();

  const _submitForm = handleSubmit(async (data) => {
    try {
      if (userIsNotBot) {
        const { firstName, lastName, email, phone, address, city, state, zipcode } = data;
        const customerAddress = `${address}, ${city}, ${state}, ${zipcode}`;
        const modifiedCustomerData = {
          firstName,
          lastName,
          email,
          phone,
          requestType,
          requestInfo,
          customerAddress,
        };

        const { status } = await axios.post(`${urls.dbApi}/email-service/send-ccpa-request`, {
          formData: modifiedCustomerData,
        });

        if (status === 200) {
          return history.push({
            pathname: '/ccpa/privacy-contact/completed',
            state: { message: message({ firstName, lastName }) },
          });
        }
      } else {
        throw error;
      }
    } catch (err) {
      console.error(err);
      if (!err) {
        setError(true);
        return setErrMsg('Please complete reCaptcha');
      }
      setError(true);
      setErrMsg(err.response.data.message);
    }
  });

  const onChangeTest = (value) => {
    if (value) setIsNotBot(true);
  };

  const { firstName, lastName, email, phone, address, city, zipcode, state } = nameFields;

  return (
    <MDBContainer>
      <form onSubmit={_submitForm}>
        <div className={styles.name}>
          <InputField
            label={'First name'}
            id={firstName}
            name={firstName}
            arialabel={'first name'}
            register={register}
            errors={errors}
            constraints={{
              required: { value: true, message: 'Please enter first name' },
              shouldFocusError: true,
            }}
          />
          <InputField
            label={'Last name'}
            id={lastName}
            name={lastName}
            arialabel={'last name'}
            register={register}
            errors={errors}
            constraints={{
              required: { value: true, message: 'Please enter last name' },
              shouldFocusError: true,
            }}
          />
        </div>
        <div className={styles.emailPhone}>
          <InputField
            label={'Email'}
            id={email}
            name={email}
            arialabel={'email'}
            register={register}
            errors={errors}
            constraints={{
              required: 'Please enter email',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'invalid email address',
              },
              shouldFocusError: true,
            }}
          />
          <Controller
            control={control}
            defaultValue=""
            name={phone}
            rules={{
              required: { value: true, message: 'Please enter phone number' },
              // shouldFocusError: true,
              pattern: {
                value: /^(\([0-9]{3}\)\s*|[0-9]{3}\-)[0-9]{3}-[0-9]{4}$/,
                message: 'invalid phone number',
              },
            }}
            render={(formProps) => (
              <MaskedFieldInput
                label={'Phone'}
                id={'phone'}
                arialabel={'phone'}
                register={register}
                mask={'(999) 999-9999'}
                errors={errors}
                {...formProps}
              />
            )}
          />
        </div>
        <InputField
          label={'Address'}
          id={address}
          name={address}
          arialabel={'address'}
          register={register}
          errors={errors}
          constraints={{
            required: { value: true, message: 'Please enter address' },
            shouldFocusError: true,
          }}
        />
        <div className={styles.address}>
          <InputField
            label={'City'}
            id={city}
            name={'city'}
            arialabel={city}
            register={register}
            errors={errors}
            constraints={{
              required: { value: true, message: 'Please enter your city' },
              shouldFocusError: true,
            }}
          />
          <Controller
            control={control}
            defaultValue=""
            name={zipcode}
            rules={{
              required: { value: true, message: 'Please enter a zipcode' },
            }}
            render={(formProps) => (
              <MaskedFieldInput
                label={'Zip Code'}
                id={zipcode}
                arialabel={'zip code input'}
                mask="99999"
                errors={errors}
                {...formProps}
              />
            )}
          />
          <Dropdown
            label={'State'}
            id={state}
            options={stateOptions}
            className={styles.label}
            errors={errors}
            name={state}
            constraints={{ required: { value: true, message: 'Please select a state' } }}
            register={register}
          />
          <MDBRow>
            <MDBCol size="12">
              <ReCAPTCHA sitekey={process.env.GOOGLE_RECAPTCHA_V2} onChange={onChangeTest} />
            </MDBCol>

            {error && (
              <MDBCol size="12">
                <Errors message={errMsg} />
              </MDBCol>
            )}
          </MDBRow>
        </div>
        <MDBBtn color="primary" type="submit" disabled={!userIsNotBot}>
          Submit
        </MDBBtn>
      </form>
    </MDBContainer>
  );
});

Form.propTypes = {
  requestType: PropTypes.string.isRequired,
};

export default Form;
