import React, { useState, useEffect } from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBStepper,
  MDBStep,
  MDBBtn,
  MDBInput,
  MDBLink,
} from 'mdbreact';
import styles from './steps.module.scss';
import initState from './state';
import actions from './actions';

/**
 * Steps Component to render instructions along with body
 * Please ensure that steps and body are in parallel
 * Ie: step[0] = body[0]; step[1] = body[1]
 * @param {Object[]} props.steps {stepHeader, stepSubtext}
 * @param {String} props.header
 * @param {Object[]} props.body
 */
export default function Steps(props) {
  const [state, setState] = useState(initState);
  const { header, steps, body } = props;
  const { swapFormActive, handleNextPrevClick, calculateAutofocus } = actions;
  return (
    <MDBContainer>
      <h2 className="font-weight-bold text-capitalize pt-4 pb-5">{props.header}</h2>

      <MDBStepper form>
        {steps.map(({ stepHeader, stepSubtext }, i) => {
          return (
            <>
              <MDBStep form>
                <MDBLink to={`#formstep${i + 1}`} onClick={swapFormActive(i + 1, setState)(i + 1)}>
                  <MDBBtn color={state.formActivePanel === i + 1 ? 'indigo' : 'default'} circle>
                    {i + 1}
                  </MDBBtn>
                </MDBLink>
                <p>{stepHeader}</p>
                {state.formActivePanel === i + 1 ? (
                  <div className={`${styles.stepSubtext} text-leftstep-content grey lighten-4`}>
                    {stepSubtext}
                  </div>
                ) : null}
              </MDBStep>
            </>
          );
        })}
      </MDBStepper>

      <MDBRow>
        {body.map((bodyInfo, i) => {
          if (state.formActivePanel === i + 1 && bodyInfo !== '') {
            return (
              <MDBCol>
                <h3 className="font-weight-bold pl-0 my-4">Step {i + 1}</h3>
                {bodyInfo}
              </MDBCol>
            );
          }

          // if (i !== i + 1) {
          //   return (
          //     <MDBBtn
          //       color="indigo"
          //       rounded
          //       className="float-left"
          //       onClick={handleNextPrevClick(i + 1, setState)(i + 1)}>
          //       previous
          //     </MDBBtn>
          //   );
          // }
          // return (
          //   <MDBBtn
          //     key={i + 'button'}
          //     color="indigo"
          //     rounded
          //     className="float-right"
          //     onClick={handleNextPrevClick(i + 2, setState)(i + 1)}>
          //     next
          //   </MDBBtn>
          // );
        })}
      </MDBRow>
    </MDBContainer>
  );
}
