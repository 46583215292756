/**
 * @param {Object[]} inputVal
 * @param {Object[]} headers
 * @return {Object[]}
 */
const convertToDbObj = (inputVal, headers) => {
  return inputVal.reduce((tempObj, val, i) => {
    const headerValue = headers[i];
    Object.assign(tempObj, { [headerValue]: val });
    return tempObj;
  }, {});
};

export default convertToDbObj;
