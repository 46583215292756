import React, { useState } from 'react';
import {
  MDBBtn,
  MDBDatePicker,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from 'mdbreact';
import createInput from 'Comps/createInput';
import createInputCollection from 'Utils/createInputCollection';
import _toPairs from 'lodash/toPairs';

import eventsDbHeaders from '../eventsDbHeaders';

// date
// time

export default function EditEvent(props) {
  const { event, eventIndex, actions, dispatch } = props;
  const [isOpen, setState] = useState(false);

  const selectedEventModal = event[eventIndex];

  // selected Columns
  const eventId = selectedEventModal._id;

  const [date, setDate] = useState(selectedEventModal.date);

  const toggle = (nr) => () => {
    setState(!isOpen);
  };

  const dbHeadersOnlyCollection = Object.keys(selectedEventModal).reduce((filteredCols, key) => {
    eventsDbHeaders.forEach((header) => {
      Object.assign(filteredCols, { [header]: selectedEventModal[header] });
    });
    return filteredCols;
  }, {});

  const eventPairs = _toPairs(dbHeadersOnlyCollection);

  const inputCollections = createInputCollection(eventPairs, createInput, {
    name: 'events',
    hint: 'Please enter new value.',
    required: true,
  });

  const inputValuesForPut = inputCollections.reduce((temp, [value], i) => {
    const eventDbColumn = eventPairs[i][0];

    const dateCol = { date: Date.parse(new Date(date)) };
    Object.assign(temp, { [eventDbColumn]: value }, dateCol);

    return temp;
  }, {});

  return (
    <>
      <MDBBtn color="primary" size="sm" onClick={toggle()}>
        Edit Event
      </MDBBtn>
      <MDBBtn color="danger" size="sm" onClick={(e) => dispatch(actions.deleteEvent, eventId, e)}>
        Delete Event
      </MDBBtn>
      <MDBModal size="lg" isOpen={isOpen} toggle={toggle()}>
        <MDBModalHeader toggle={toggle()}>Modal title</MDBModalHeader>
        <MDBModalBody>
          {inputCollections.map(([, input], i) => (
            <div key={i}>{input}</div>
          ))}
          <MDBDatePicker
            label="Event date"
            getValue={(value) => setDate(value)}
            theme="danger"
            value={date}
          />
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="secondary" onClick={toggle()}>
            Close
          </MDBBtn>
          <MDBBtn
            onClick={(e) => dispatch(actions.updateEventInfo, inputValuesForPut, eventId, e)}
            color="primary">
            Save changes
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </>
  );
}
