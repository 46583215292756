import React, { Suspense, useState, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { MDBContainer } from 'mdbreact';
import Spinner from '@Ui/Spinner';
import Private from 'Comps/Private';
import Footer from 'Comps/Footer/index';
import Complete from 'Comps/Complete/complete';
import Login from '../Login';
import Ccpa from '../Ccpa/ccpa';

//  {/* </Suspense> */}
export default function App() {
  const { token } = JSON.parse(localStorage.getItem('admin')) || {};

  const renderComponent = () => {
    if (window.location.href.indexOf('/ccpa/privacy-contact/') > -1) {
      return (
        <>
          <Route exact path="/ccpa/privacy-contact/">
            <>
              <Ccpa />
            </>
          </Route>
          <Route exact path="/ccpa/privacy-contact/completed">
            <Complete />
          </Route>
          <Footer />
        </>
      );
    } else if (token) {
      return <Private />;
    }
    return (
      <>
        <Redirect to="/login" />
        <Route exact path="/login">
          <MDBContainer>
            <Login />
          </MDBContainer>
        </Route>
      </>
    );
  };

  return renderComponent();
}
