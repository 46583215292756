import React, { useState } from 'react';
import { MDBCol, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import ImgUploadInfo from '../../../Components/ImgUploadInfo';
import Error from '../../../Components/ResultHandlers/Error';

import createInput from '../../../Components/createInput';

import styles from '../styles/index.module.scss';

export default function NewTrainer(props) {
  const { dispatch, actions, state } = props;
  const [isOpen, openModal] = useState(false);

  const [name, nameInput] = createInput({
    name: 'trainer-name',
    initialVal: '',
    label: 'Trainer Name',
    required: true,
  });

  const [text, infoInput] = createInput({
    name: 'trainer-info',
    initialVal: '',
    label: 'Trainer Info',
    required: true,
    type: 'textarea',
  });

  const [imgLink, imgInput] = createInput({
    name: 'trainer-img',
    initialVal: '',
    label: 'Trainer image link',
    required: true,
  });

  const toggle = (e) => {
    e.preventDefault();
    openModal(!isOpen);
  };

  return (
    <section className="new-trainer">
      <MDBBtn color="primary" onClick={toggle}>
        New Trainer
      </MDBBtn>
      <MDBModal isOpen={isOpen} toggle={toggle} size="lg">
        <MDBModalHeader toggle={toggle}>New Trainer</MDBModalHeader>
        <form onSubmit={(e) => dispatch(actions.addTrainer, { name, text, imgLink }, e)}>
          <MDBModalBody>
            {nameInput}
            {infoInput}
            <MDBCol>
              <div>Trainer Image:</div>
              <img src={imgLink} className={`${styles.trainerImg} img-fluid`} />
              {imgInput}
              <ImgUploadInfo />

              {state.newUserErr ? (
                <Error message="There was an issue when adding a new trainer, please try again." />
              ) : null}
            </MDBCol>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={toggle}>
              Close
            </MDBBtn>
            <MDBBtn type="submit" color="primary">
              Save changes
            </MDBBtn>
          </MDBModalFooter>
        </form>
      </MDBModal>
    </section>
  );
}
