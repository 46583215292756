import _capitalize from 'lodash/capitalize';
/**
 * to input pairs arr for input to ensure data is editable to be submit
 * to database for updates
 * @param {Object[]} pairsArr
 * @param {Function} createInput
 * @param {String} options.name
 * @param {String} options.hint
 * @param {String} options.type To show password/textarea
 * @param {Boolean} options.required
 * @returns {Object[]} return pairsArr with [inputVal, input]
 */
const createInputCollection = (pairsArr, createInput, options) => {
  const { name, hint, type, required, className } = options || {};

  return pairsArr.map(([label, initialVal]) => {
    const [inputVal, input] = createInput({
      name: label + '-' + name,
      hint,
      initialVal,
      type,
      required,
      label: _capitalize(name) + ' ' + label,
      className,
    });

    return [inputVal, input];
  });
};

export default createInputCollection;
