import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import initialState from './state';
import actions from './actions';
import helper from './helper';

// comp
import Components from './Components/Components';

export default function Events(props) {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    (async () => {
      actions.init(state, setState);
    })();
  }, []);

  const dispatch = (action, ...args) => {
    return action(state, setState, ...args);
  };
  return (
    <section className="events" id="events">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Store Events</title>
      </Helmet>
      <Components dispatch={dispatch} actions={actions} state={state} />;
    </section>
  );
}
