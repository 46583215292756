import React, { useState } from 'react';
import { MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink } from 'mdbreact';
import styles from './styles.module.scss';

/**
 * Create navgiation tabs with bodies to match
 * Header and bodies need to be in same index in both arrays
 * @param {Object[]} props.headers
 * @param {Object[]} props.bodies
 */
export default function Tabs(props) {
  const { headers, bodies } = props || {};
  const [activeTab, setTab] = useState('1');

  const toggleTab = (tab) => () => {
    if (activeTab !== tab) {
      setTab(tab);
    }
  };
  return (
    <div className="classic-tabs">
      <MDBNav classicTabs color="cyan">
        {headers.map((header, i) => (
          <MDBNavItem key={i}>
            <MDBNavLink
              link
              to="#"
              active={activeTab === `${i + 1}`}
              onClick={toggleTab(`${i + 1}`)}>
              {header}
            </MDBNavLink>
          </MDBNavItem>
        ))}
      </MDBNav>

      <MDBTabContent className={styles.tabContent} activeItem={activeTab}>
        {bodies.map((body, i) => (
          <MDBTabPane key={i} tabId={`${i + 1}`}>
            {body}
          </MDBTabPane>
        ))}
      </MDBTabContent>
    </div>
  );
}
