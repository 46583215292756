import Axios from 'axios';
import urls from 'Utils/urls';
import swal from 'sweetalert2';

const { success, error, warning } = require('Comps/sweetAlert/index');

export default {
  /**
   * Upload photo submission to database
   * @param {Object} newPhotoSubmission
   * @param {fileInfo} fileInfo
   * @return null eventTrigger
   */
  uploadSubmission: async (newPhotoSubmission, file) => {
    const formData = new FormData();
    formData.append('newPhotoSubmission', file);
    swal.fire({
      title: 'Uploading',
      text: 'Please wait while image is processed.',
      onBeforeOpen: async () => {
        try {
          const { status } = await Axios.post(
            `${urls.dbApi}/photo-submissions/upload-submission`,
            formData,
            { params: { newPhotoSubmission } },
            { headers: { 'Content-Type': 'multipart/form-data' } },
          );
          if (status !== 200) return error({ title: 'Error', text: 'Please check error' });
          success({ title: 'Success!', text: 'Photo Uploaded.' });
        } catch (err) {
          console.error(err);
          error({ title: 'Error', text: 'Please review error' });
        }
      },
    });
  },
  /**
   * delete submission from database
   * @param {Number} id
   */
  deleteSubmission: async (id) => {
    try {
      const { status } = await Axios.delete(
        `${urls.dbApi}/photo-submissions/delete-submission/${id}`,
      );
      if (status !== 200) return error({ title: 'Error', text: 'Please check error' });
      success({ title: 'Success!', text: 'Submission deleted!' });
    } catch (err) {
      console.error(err);
      return error({ title: 'Error', text: 'Server error. Try again.' });
    }
  },
};
