import React, { useState } from 'react';
import { MDBSwitch } from 'mdbreact';

export default function Switch(props) {
  const [state, setState] = useState(false);

  return (
    <MDBSwitch
      className="ml-2"
      checked={state}
      labelLeft="Off"
      labelRight="Create Wasabi Links"
      getValue={props.getValue}
      onChange={() => setState(!state)}
    />
  );
}
