import React from 'react';
import { Route, Link } from 'react-router-dom';
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdbreact';

import NewEvent from './NewEvent';
import SelectStore from './SelectStore';
import EventList from './EventList';
import Header from '@Ui/Header';

export default function Components(props) {
  return (
    <MDBContainer>
      <MDBRow>
        <MDBCol size="12">
          <Header headerTag="h1" text="Events" />
        </MDBCol>

        <MDBCol size="12">
          <Link to="/events/new-event">
            <MDBBtn color="primary" className="mt-3">
              Add new event
            </MDBBtn>
          </Link>
        </MDBCol>

        <SelectStore {...props} />
      </MDBRow>
      <section className="event-list">
        <EventList {...props} />
      </section>
      <Route exact path="/events/new-event">
        <NewEvent {...props} />
      </Route>
    </MDBContainer>
  );
}
