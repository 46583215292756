import React from 'react';
import LazyImage from 'Comps/LazyLoad/lazyImage';
import styles from './logo.module.scss';

const Logo = React.memo(function Logo() {
  return (
    <a className={styles.logoWrapper} href="https://centinelafeed.com">
      <LazyImage
        src="https://cdn11.bigcommerce.com/s-nu7v662ya9/images/stencil/original/logo-01_1586382922__54064.original.png"
        alt="Centinela Feed logo"
      />
    </a>
  );
});

export default Logo;
