import React from 'react';
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardImage,
  MDBCardText,
  MDBIcon,
} from 'mdbreact';
import moment from 'moment';
import styles from '../styles/Events.module.scss';

import EditEvent from './EditEvent';

export default function EventList(props) {
  const { selectedEvents, events } = props.state;

  return (
    <MDBRow>
      {selectedEvents.map((event, i) => {
        const { name, location, link, alt, date, tags, img, text, time, _id } = event;
        return (
          <MDBCol key={i} size="12" md="4" className="my-3">
            <MDBCard wide>
              <MDBCardImage style={{ maxHeight: '185px' }} src={img} />

              <MDBCardBody cascade className="text-center">
                <MDBCardText>
                  <label className={styles.label}>Image alt: </label>
                  {alt}
                </MDBCardText>
                <MDBCardTitle>
                  <h2 className={`${styles.header2} mb-2`}>{name}</h2>
                  <address className={styles.address}>{location}</address>
                  <p className={styles.label}>
                    <MDBIcon icon="calendar-alt" /> {moment(date).format('ddd MMM DD YYYY')}
                  </p>
                  <label className={styles.label}>Event time: {time}</label>
                </MDBCardTitle>
                <MDBCardText>
                  <label className={styles.label}>Event information: </label>
                  {text}
                </MDBCardText>
                <MDBCardText>
                  <label className={styles.label}>Event Link: </label>
                  <a href={link}>{link}</a>
                </MDBCardText>
                <EditEvent eventId={_id} event={selectedEvents} eventIndex={i} {...props} />
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        );
      })}
    </MDBRow>
  );
}
