import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import createSelectBrand from '../SelectBrand';
import Components from './Components';
import actions from './actions';
import initialState from './state';

export default function InventoryReports(props) {
  const [brandId, selectBrand] = createSelectBrand();
  const [state, setState] = useState(initialState);

  const dispatch = (action, ...args) => {
    return action(setState, state, ...args);
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CSV Files Upload</title>
      </Helmet>
      <Components
        selectBrand={selectBrand}
        brandId={brandId}
        dispatch={dispatch}
        actions={actions}
        state={state}
      />
    </>
  );
}
