import React from 'react';

export default [
  {
    stepHeader: 'Prepare CSV File',
    stepSubtext: (
      <>
        <p>
          <strong>Check File: </strong> <br />
          Check all column names to ensure they are consistent with this file. If not consistent,
          please fix to ensure that it will work with the tools provided.
        </p>
        <p>
          <strong>Check Product Custom Fields:</strong> <br />
          Make sure that it follows this convention: "="; =; "Feeding Type= raw - freeze dried";
          "Protein=Beef & Hoki" The “=”; =; are okay as long as all the other text matches in a
          similar manner
        </p>
        <p>
          <strong>Check images:</strong> <br /> If images links either have the accessible by
          BigCommerce, or are already existing Wasabi Links AND BOTH NEEDS TO HAVE THE RIGHT SIZING,
          move onto step 3. If images are inaccessible on BigCommerce, please have vendor check and
          resend image links If images sizing are incorrect, please go to the next step If images
          are on Wasabi, you can tell if images sizing are too big if they are typically more than
          500k/b
        </p>
      </>
    ),
  },
  {
    stepHeader: 'Updating CSV File',
    stepSubtext: (
      <>
        <p>
          <strong>Images not on Wasabi?</strong> <br />
          If images are not on Wasabi, please turn on &quot;Create Wasabi Links&quot; to upload the
          images onto Wasabi. <br /> <br />
          Make sure image columns name matches exactly the following: &quot;Product Image
          Description - 1&quot;. Please make sure that Product Image Description - 1 is filled. This
          does not need to be unique. <br /> <br />
          After submitting CSV file, the program will notify you of any improper links. You can
          upload the same file multiple times, but please limit them to 3 times per 30 minutes if
          there are more than 250 + image links per upload.
          <br /> <br />
          Once files are successfully uploaded onto Wasabi, please watch the following video to
          complete resizing. (If you have not done it before). <br /> <br />
          <a href="#">Resize Instructions</a>
          <br /> <br />
          Once Resize is finished, click on &quot;Complete Conversion&quot; at the bottom to, wait
          until you are prompted to download a file to be upload onto BigCommerce.
        </p>
        <p>
          <strong>Images on Wasabi?</strong> <br />
          If you do not need to upload the images to Wasabi, simply upload the file, submit,
          download file and move onto <strong>step 3.</strong>
        </p>
      </>
    ),
  },
  {
    stepHeader: 'Checking and Uploading',
    stepSubtext: (
      <>
        <p>
          <strong>File for Submission:</strong> <br />
          The final file should only include the Parents product The child SKU’s already been
          updated in step 3. <br />
          Final spot check in the name, description, and Product Custom Fields These should not
          change.
        </p>

        <p>
          <strong>BigCommerce Upload Overview:</strong>
          1. Go to BigCommerce <br />
          2. Products => Import <br />
          3. Chose file => “File was exported using ‘Bulk Eidt’ Template <br />
          <strong>*Do not change anything else*</strong>
          <br />
          <br />
          <strong>Field Selections: </strong>
          Please carefully go through this as it can potentially mess up the import
          <br />
          <br />
          1. Ensure Product/Code SKU matches with CF SKU # <br />
          2. Product Name => Consumer Friendly Name <br />
          3. Category => Department & Class Combined <br />
          Everything else can be left alone.
          <br />
          <br />
          <strong>Finish Importing:</strong>
          Next page then Start Import <br />
          Once import finishes, you will see some warnings, don’t worry it’s expected
          <br />
          1. Click on view imported Products 2. <br />
          2.Spot check the newly imported products to ensure that description and images show on
          front-end for consumer
          <br />
          <br />
          Then select all newly imported product => bulk edit => checkmark “eye” icon to ensure that
          they are visible on the front-end
        </p>
      </>
    ),
  },
];
