import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBDatePicker,
  MDBSelect,
} from 'mdbreact';
import helper from '../helper';
import _toPairs from 'lodash/toPairs';

import createInput from 'Comps/createInput';
import Error from 'Comps/ResultHandlers/Error';

const { success } = require('../../../Components/sweetAlert');

const eventsDbHeaders = [
  { label: 'name', value: '', hint: 'event name' },
  { label: 'location', value: '', hint: 'event location address' },
  { label: 'link', value: '', hint: 'event website' },
  { label: 'img', value: '', hint: 'image link url' },
  { label: 'alt', value: '', hint: 'short 2 to 3 words description of image event' },
  { label: 'text', value: '', hint: 'information about event' },
  { label: 'time', value: '', hint: 'event time. Format as 2:00 PM - 4:00 PM ' },
];

export default function NewEvent(props) {
  const { dispatch, actions } = props;
  const { newStoreMdbInputs } = props.state;

  const [date, setDate] = useState(new Date());
  const [storeId, setState] = useState(0);

  const history = useHistory();

  const inputCollections = helper.createInputCollection(eventsDbHeaders, {
    name: 'events',
    hint: 'Please enter new value.',
  });

  const getStoreId = (value) => {
    setState(value[0]);
  };

  const toggle = () => {
    history.push('/events');
  };

  const newEventValues = inputCollections.reduce((temp, [value, , eventDbColumn]) => {
    const dateCol = { date: Date.parse(new Date(date)) };
    const storeIdCol = { store_id: parseInt(storeId, 10) };
    Object.assign(temp, { [eventDbColumn]: value }, dateCol, storeIdCol);

    return temp;
  }, {});

  return (
    <section className="events__new-event">
      <MDBModal size="lg" toggle={toggle} isOpen={true}>
        <MDBModalHeader toggle={toggle}>Add new event</MDBModalHeader>
        <form onSubmit={(e) => dispatch(actions.newEvent, newEventValues, e)}>
          <MDBModalBody>
            <MDBCol size="6">
              <MDBSelect
                required
                search={true}
                label="Select store for event"
                options={newStoreMdbInputs}
                getValue={(value) => getStoreId(value)}
              />
            </MDBCol>
            {inputCollections.map(([val, input], i) => (
              <>
                <MDBCol size="12" key={i}>
                  {input}
                </MDBCol>
              </>
            ))}
            <MDBCol size="12">
              <label>Event Date</label>
              <MDBDatePicker
                className="mt-0"
                getValue={(value) => setDate(value)}
                theme="danger"
                value={date}
              />
            </MDBCol>
          </MDBModalBody>
          <MDBModalFooter>
            <Link to="/events">
              <MDBBtn color="secondary">Close</MDBBtn>{' '}
            </Link>
            <MDBBtn type="submit" color="primary">
              Create New Event
            </MDBBtn>
          </MDBModalFooter>
        </form>
      </MDBModal>
    </section>
  );
}
