import React from 'react';
import { MDBRow, MDBCol, MDBBtn, MDBInput } from 'mdbreact';
import _replace from 'lodash/replace';

import convertToDbObj from 'Utils/convertToDbObj';

import createInput from 'Comps/createInput';
import ImgUploadInfo from 'Comps/ImgUploadInfo';

export default function BasicInfo(props) {
  const { currentStore, dispatch, actions, storeId } = props;
  const { availableServices } = currentStore;

  if (Object.keys(currentStore).length > 0) {
    const [img, mainImgInput] = createInput({
      name: 'main-image',
      initialVal: currentStore.img,
      label: 'Main image link',
    });

    const [smallImg, smallImgInput] = createInput({
      name: 'small-image',
      initialVal: currentStore.smallImg,
      label: 'Small image link',
    });

    const [address, addressInput] = createInput({
      name: 'address',
      initialVal: currentStore.address,
      label: 'Store address',
    });

    const [phone, phoneInput] = createInput({
      name: 'phone',
      initialVal: currentStore.phone,
      label: 'Store number',
    });

    const [availServices, availServicesInput] = createInput({
      name: 'available-services',
      initialVal: availableServices.join('; '),
      label: 'Available Services',
    });

    // Please keep these consistent if the needs for updating
    // comes up. If not, it might not update the input data correctly
    const basicVals = [img, smallImg, address, phone, availServices.split(';')];
    const basciValHeaders = ['img', 'smallImg', 'address', 'phone', 'availableServices'];

    const basicInfo = convertToDbObj(basicVals, basciValHeaders);

    return (
      <section className="store__basicinfo">
        <MDBRow className="stores__store-imgs mb-3">
          <MDBCol size="12" sm="6" className="stores__main-img">
            <div>Main store Image</div>
            <img src={currentStore.img} className="img-fluid" alt="Main store image" />
            {mainImgInput}
            <ImgUploadInfo />
          </MDBCol>
          <MDBCol size="12" sm="6" className="stores__google-map-img">
            <div>Google Map Image</div>
            <img src={currentStore.smallImg} className="img-fluid" />
            {smallImgInput}
            <ImgUploadInfo />
          </MDBCol>
        </MDBRow>
        <address>
          <div className="font-weight-bold blue-text">{addressInput}</div>
          <div className="store-phone">{phoneInput}</div>
        </address>
        {availServicesInput}
        <div>
          <small>Please separate services with semi-colon.</small>
        </div>
        <MDBBtn
          onClick={(e) => dispatch(actions.updateStoreInformation, { basicInfo, storeId }, e)}
          color="primary"
          size="sm"
          className="mb-3">
          Update basic info
        </MDBBtn>
      </section>
    );
  }
  return null;
}
