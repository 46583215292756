import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import TrainerInfo from './TrainerInfo';
import NewTrainer from './NewTrainer';

import styles from '../styles/index.module.scss';
import Header from '@Ui/Header';

export default function Components(props) {
  const { state } = props;
  return (
    <section className="trainers">
      <MDBContainer>
        <MDBRow>
          <MDBCol size="12">
            <Header headerTag="h1" text="Trainers Information" />
          </MDBCol>
          <MDBCol size="12">
            <NewTrainer {...props} />
          </MDBCol>
          {state.trainers.map((trainer, i) => (
            <MDBCol className={`${styles.trainerInfo} my-3 py-5`} key={i} size="12">
              <TrainerInfo trainer={trainer} trainerIndex={i} {...props} />
            </MDBCol>
          ))}
        </MDBRow>
      </MDBContainer>
    </section>
  );
}
