import React, { Fragment, useState, useEffect, useRef } from 'react';
import Axios from 'axios';
import urls from 'Utils/urls';
import getStores from 'Utils/requests/getStores';
import { Helmet } from 'react-helmet';

import initialState from './state';
import actions from './actions';
import helper from './helper';

import Components from './Components/Components';

export default function Stores(props) {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    const init = async () => {
      try {
        const data = await getStores();
        const mdbSelectOpts = await helper.convertToMDBSelectCol(data);
        const initialSelectedStore = data[0];

        initialSelectedStore.storeIndex = 0;
        setState({ ...state, stores: data, mdbSelectOpts, currentStore: initialSelectedStore });
      } catch (err) {
        console.error(err);
      }
    };
    init();
  }, []);

  const dispatch = (action, ...args) => {
    return action(state, setState, ...args);
  };

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Cf Stores</title>
      </Helmet>
      <Components dispatch={dispatch} state={state} actions={actions} />
    </Fragment>
  );
}
