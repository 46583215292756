import React from 'react';
import { MDBAnimation } from 'mdbreact';

/**
 * Render message info
 * @param {String} message 
 */
export default function Error({ message }) {
  return (
    <MDBAnimation type="shake" duration="800ms">
      <div className="text-danger font-weight-bold">{message}</div>
    </MDBAnimation>
  );
}
