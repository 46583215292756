import React from 'react';
import PropTypes from 'prop-types';
import {
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
} from 'mdbreact';

const PhotoSubmissions = React.memo(function PhotoSubmissions({ photoSubmissions, actions }) {
  return (
    <MDBRow className="mt-5">
      {photoSubmissions.map(({ _id, name, imgSrc, alt, holidayName }) => (
        <MDBCol md="4" key={_id}>
          <MDBCard>
            <MDBCardImage
              top
              src={imgSrc}
              overlay="white-slight"
              hover
              waves
              alt="MDBCard image cap"
            />
            <label>{alt}</label>
            <MDBCardBody className="dark-text rounded-bottom">
              {/* <a href="#!" className="activator waves-effect waves-light mr-4">
                <MDBIcon icon="share-alt" className="white-text" />
              </a> */}
              <MDBCardTitle>{name}</MDBCardTitle>
              <hr className="hr-light" />
              <MDBCardText className="dark-text">Holiday name: {holidayName}</MDBCardText>
              <MDBBtn color="danger" size="sm" onClick={(e) => actions.deleteSubmission(_id)} className="float-right">
                Delete
              </MDBBtn>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      ))}
    </MDBRow>
  );
});

PhotoSubmissions.propTypes = {
  photoSubmissions: PropTypes.array.isRequired,
};

export default PhotoSubmissions;
