import _map from 'lodash/map';

const helper = {
  convertHeaderInputsToArr: (str) => {
    return str.split(';');
  },

  /**
   * @param {String} tableClassName
   * @return {object[]} new array object with html values
   */
  addGAValuesToGAColumn: (tableClassName) => {
    const tables = document.querySelectorAll('.' + tableClassName);
    return _map(tables, (table) => {
      const temp = {};
      Object.assign(temp, { cfSku: table.id }, { templateVal: table.outerHTML });
      return temp;
    });
  },
};

export default helper;
