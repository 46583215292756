import React from 'react';
import { Route, Link } from 'react-router-dom';
import { MDBRow, MDBCol, MDBContainer, MDBBtn } from 'mdbreact';
import { DateRangePicker } from 'react-date-range';
import { Helmet } from 'react-helmet';
import urls from 'Utils/urls';
import { JobProvider } from '../Jobs/index';
import Jobs from '../Jobs/Jobs';
import Progress from '../Progress';
import { useCustomerReferralStore } from './index';

/**
 * selectionRange { startDate: new Date(), endDate: new Date(), key: 'selection' }
 */

const CustomerReferral = React.memo(function CustomerReferral() {
  const { api, state } = useCustomerReferralStore();
  const { jobs, warningMessage, selectionRange, error, errorMessage } = state;
  const { selectDateRange, getFile } = api;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Customer Referral CSV</title>
      </Helmet>
      <MDBContainer>
        <Route exact path="/customer-referral">
          <h1>Customer Referral Data</h1>
          <MDBRow>
            <MDBCol size="12">
              <DateRangePicker ranges={[selectionRange]} onChange={selectDateRange} />
            </MDBCol>
            <MDBCol>
              <MDBBtn color="primary" onClick={getFile}>
                Get file
              </MDBBtn>
              <Link to="/customer-referral/jobs">
                <MDBBtn>View completed csvs</MDBBtn>
              </Link>
            </MDBCol>
          </MDBRow>
          {jobs.length > 0 && (
            <MDBRow className="mt-5">
              <MDBCol>
                <h2>Download in Progress</h2>
                {jobs.map(({ id, progress }) => (
                  <Progress key={id} id={id} urlPath="customer-referral" />
                ))}
              </MDBCol>
            </MDBRow>
          )}
        </Route>
        <Route path="/customer-referral/jobs">
          <h1>Completed CSV Files</h1>
          <JobProvider urlPath="customer-referral">
            <Jobs urlPath="customer-referral" />
          </JobProvider>
        </Route>
        {/* <Jobs urlPath="customer-referral" /> */}
      </MDBContainer>
    </>
  );
});

export default CustomerReferral;
