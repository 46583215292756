import React, { useState, useEffect } from 'react';
import { MDBInput } from 'mdbreact';

/**
 * Renders input component
 * @param {Object} props
 * @returns {Object} [value, input]
 * @example {className, name, icon, hint, id, size, type, required, initialVal, label }
 */
export default function createInput(options) {
  const { className, name, icon, hint, id, size, type, required, initialVal, label } =
    options || {};

  const initialState = initialVal || '';

  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(initialState);
  }, [initialState]);

  const input = (
    <MDBInput
      className={className}
      onChange={(e) => setValue(e.target.value)}
      name={name}
      icon={icon}
      hint={hint}
      label={label}
      id={id}
      size={size}
      value={value}
      type={type}
      required={required}
    />
  );
  return [value, input];
}
