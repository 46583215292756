import React from 'react';
import { MDBBtn } from 'mdbreact';
import PropTypes from 'prop-types';

/**
 * @param {Object} props
 * @param {Func} props.onSubmit
 * @param {Object[]} props.inputs
 */
export default function FormInput({ children, onSubmit, buttonText }) {
  return (
    <form onSubmit={onSubmit}>
      {children}
      <MDBBtn type="submit">{buttonText}</MDBBtn>
    </form>
  );
}

FormInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.array,
  buttonText: PropTypes.string,
};
