import React from 'react';
import { MDBCol } from 'mdbreact';
import _toPairs from 'lodash/toPairs';

// comps
import BasicInfo from './BasicInfo';
import StoreHours from './StoreHours';
import StoreServices from './StoreServices';

export default function StoreCards(props) {
  const {
    state: { currentStore },
  } = props;

  const storeHours = _toPairs(currentStore.hours);
  return (
    <MDBCol size="12">
      <h2 className="header2">
        <strong>{currentStore.name} Store</strong>
      </h2>
      <BasicInfo currentStore={currentStore} storeId={currentStore._id} {...props} />
      <StoreHours
        storeHours={storeHours}
        storeIndex={currentStore.storeIndex}
        storeId={currentStore._id}
        {...props}
      />
      {currentStore.services ? (
        <StoreServices
          services={currentStore.services}
          storeIndex={currentStore.storeIndex}
          storeId={currentStore._id}
          {...props}
        />
      ) : null}
    </MDBCol>
  );
}
