import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import Header from '../../Components/Headers';
import Footer from '../../Components/Footer';

// Components
// const FileUpload = React.lazy(() => import('../CsvFileUpload'));
// const FeedingGuide = React.lazy(() => import('../../Components/FeedingGuide'));
// const Stores = React.lazy(() => import('../Stores'));
// const Trainers = React.lazy(() => import('../Trainers'));
// const Events = React.lazy(() => import('../Events'));
// const HolidayPhotos = React.lazy(() => import('../HolidayPhotos'));
// const InventoryReports = React.lazy(() => import('../InventoryReports'));

import FileUpload from '../../Containers/CsvFileUpload';
// import FeedingGuide from '../../FeedingGuide';
import Stores from '../../Containers/Stores';
import Trainers from '../../Containers/Trainers';
import Events from '../../Containers/Events';
import HolidayPhotos from '../../Containers/HolidayPhotos';
import Orders from '../../Containers/Orders';
import InventoryReports from '../../Containers/InventoryReports';
import Ccpa from '../../Containers/Ccpa/ccpa';
import { CustomerReferralProvider } from '../../Containers/CustomerReferrals/index';
import CustomerReferrals from '../../Containers/CustomerReferrals/CustomerReferral';

export default function Private() {
  return (
    <>
      <Header />
      <main className="main-body">
        <Switch>
          <Route exact path="/stores">
            <Stores />
          </Route>
          <Route exact path="/trainers">
            <Trainers />
          </Route>
          <Route path="/events">
            <Events />
          </Route>
          <Route exact path="/csv-upload">
            <FileUpload />
          </Route>
          {/* <Route exact path="/feeding-guide">
      <FeedingGuide />
    </Route> */}
          <Route path="/holiay-photo-upload">
            <HolidayPhotos />
          </Route>
          <Route path="/orders">
            <Orders />
          </Route>
          <Route path="/ccpa">
            <Ccpa />
          </Route>
          <Route path="/customer-referral">
            <CustomerReferralProvider>
              <CustomerReferrals />
            </CustomerReferralProvider>
          </Route>
        </Switch>
      </main>
      <Footer />
      {/* </Suspense> */}
    </>
  );
}
