import React from 'react';
import PropTypes from 'prop-types';
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from 'mdbreact';

/**
 * @param {String} props.size
 * argument: sm, md, lg
 */
export default function Modals({ header, body, buttonText, size, isOpen, toggle }) {
  return (
    <MDBRow>
      <MDBCol size="12">
        {buttonText && (
          <MDBBtn color="info" onClick={() => toggle(!isOpen)}>
            {buttonText}
          </MDBBtn>
        )}
        <MDBModal isOpen={isOpen} toggle={() => toggle(!isOpen)} size={size || 'md'}>
          <MDBModalHeader toggle={() => toggle(!isOpen)}>{header}</MDBModalHeader>
          <MDBModalBody>{body}</MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={() => toggle(!isOpen)}>
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBCol>
    </MDBRow>
  );
}

Modals.propTypes = {
  header: PropTypes.string,
  body: PropTypes.object.isRequired,
  buttonText: PropTypes.string,
  size: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};
