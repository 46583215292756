import React, { useState, useEffect } from 'react';
import _isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './inputField.module.scss';

/**
 * Form will use react form hooks
 * There are 2 inputs this uses react hook form
 * @param {String} props.value
 * @param {Function} props.onChange
 * @param {Object} props.ref
 */
const InputField = React.memo(function InputField({
  id,
  label,
  hint,
  disabled,
  required,
  placeholder,
  constraints,
  register,
  errors,
  formFor,
  type,
} = {}) {
  const [focus, setFocus] = useState(false);
  // rendering value simply to capture focus
  const [value, setValue] = useState('');
  useEffect(() => {
    if (value !== '' || value) {
      setFocus(true);
    }
  }, []);
  return (
    <div className="md-form">
      <span className={styles.hint} aria-labelledby="hint for input">
        {hint}
      </span>
      <input
        className={classNames('form-control active', styles.input)}
        disabled={disabled || false}
        required={required || false}
        id={formFor + id}
        label={label}
        name={id}
        placeholder={placeholder}
        ref={register && register(constraints)}
        onChange={({ target }) => setValue(target.value)}
        type={type}
        aria-disabled={`${disabled}` || 'false'}
        onFocus={() => setFocus(true)}
        onBlur={() => {
          if (_isEmpty(value) || !value) {
            setFocus(false);
          }
        }}
      />
      <label aria-labelledby={label} className={classNames('active', styles.label)} htmlFor={id}>
        {label}
      </label>
      {errors && errors[id] && (
        <div className={styles.error}>{errors[id].message}</div>
        //    <div className={styles.error}>{get(errors, errorMessage[errors[id].type])}</div>
      )}
    </div>
  );
});

InputField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  hint: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  constraints: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  formFor: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  ref: PropTypes.object,
};
export default InputField;
