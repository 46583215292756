import React, { useEffect, useState, createContext, useContext } from 'react';
import Axios from 'axios';
import fileDownload from 'js-file-download';
import produce from 'immer';
import urls from 'Utils/urls';
import setToken from 'Utils/requests/setToken';

const tokenHeader = { headers: setToken('admin') };

const JobsContext = createContext();

const initialState = {
  jobs: [],
  downloading: false,
  error: false,
  errorMessage: '',
};

function jobsApi(state, setState, urlPath) {
  const getJobs = async () => {
    try {
      const apiPath = urlPath === 'orders' ? urls.bcApi : urls.dbApi;
      const { data } = await Axios.get(`${apiPath}/${urlPath}/get-completed-jobs`, tokenHeader);
      const lastJobs = data.slice(-20);
      const { jobs } = state;
      setState(
        produce((draft) => {
          draft.jobs = [...lastJobs, ...jobs];
        }),
      );
    } catch (err) {
      produce((draft) => {
        draft.error = true;
        draft.errorMessage =
          err.response.data.message ||
          `Status: ${err.response.status}. Something went wrong when trying to get jobs information. Please try again or check your server.`;
      });
    }
  };
  const getJobData = async (id) => {
    try {
      const apiPath = urlPath === 'orders' ? urls.bcApi : urls.dbApi;
      const { data } = await Axios.get(`${apiPath}/${urlPath}/get-job-data/${id}`, tokenHeader);
      const { fileName, csv } = data;
      fileDownload(csv, fileName);
    } catch (err) {
      setState(
        produce((draft) => {
          draft.error = true;
          draft.errorMessage =
            err.response.data.message ||
            `Status: ${err.response.status}. Something went wrong in the download process. Please try again or check your server`;
        }),
      );
    } finally {
      setState(
        produce((draft) => {
          draft.downloading = false;
        }),
      );
    }
  };
  return { getJobs, getJobData };
}

function JobProvider({ children, urlPath }) {
  const [state, setState] = useState(initialState);
  const api = jobsApi(state, setState, urlPath);
  const { getJobs } = api;

  useEffect(() => {
    (async () => getJobs())();
  }, []);

  const value = { api, state };

  return <JobsContext.Provider value={value}>{children}</JobsContext.Provider>;
}

const jobStore = () => useContext(JobsContext);

export { JobProvider, jobStore };
