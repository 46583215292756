import React from 'react';
import { MDBSelect, MDBCol } from 'mdbreact';

export default function SelectStore(props) {
  const { actions, dispatch } = props;
  const { storeEventsInput } = props.state;

  return (
    <MDBCol size="5">
      <MDBSelect
        getValue={(value) => dispatch(actions.getSelectedStoreEvents, value)}
        label="Find store events"
        search={true}
        selected="Select a brand"
        options={storeEventsInput}
      />
    </MDBCol>
  );
}
