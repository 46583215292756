import React, { Fragment } from 'react';

export default function CsvCollections(props) {
  const { tempCsvFiles } = props.state;
  const { dispatch, actions } = props;
  return (
    <Fragment>
      {tempCsvFiles.map((fileName, i) => (
        <div className="cvs-files" key={i}>
          <a href="#" onClick={(e) => dispatch(actions.downloadCsvFiles, fileName, e)}>
            {fileName}
          </a>
        </div>
      ))}
    </Fragment>
  );
}
