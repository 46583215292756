import React from 'react';
import PropTypes from 'prop-types';
import { MDBSpinner } from 'mdbreact';
import styles from './spinner.module.scss';

export default function Spinner(props) {
  const { className } = props;
  return (
    <div className={styles.wrapper}>
      <MDBSpinner className={className} red big />
    </div>
  );
}

Spinner.propsType = {
  className: PropTypes.string,
};
