import Axios from 'axios';
import urls from 'Utils/urls';

const { success, confirmDelete } = require('../../Components/sweetAlert/index.jsx');

const actions = {
  /**
   * @param prevState
   * @param setState
   * @param { nameVal }
   * @param { txtInfo }
   * @return { status, message }
   */
  updateTrainerInfo: async (prevState, setState, options, e) => {
    e.preventDefault();
    const { nameVal, textInfo, _id, imgLink, websiteLink, videoLink } = options || {};
    const dbId = _id;
    try {
      const {
        status,
        data: { data },
      } = await Axios.put(`${urls.dbApi}/trainers/update-trainer`, {
        data: { nameVal, textInfo, dbId, imgLink, websiteLink, videoLink },
      });

      if (status === 200) {
        setState({ ...prevState, error: false });
        success({ title: 'Success!', text: 'Trainer info updated.', button: 'Okay' });
      }
    } catch (err) {
      console.error(err);
      setState({ ...prevState, error: true });
    }
  },

  /**
   * @param {Object} prevState
   * @param {Function} setState
   * @param {Number} options._id
   * @param {Number} options.trainerIndex index position from trainersArr
   */
  deleteTrainer: async (prevState, setState, options, e) => {
    const { _id, trainerIndex } = options || {};
    e.preventDefault();
    try {
      const result = await confirmDelete({
        title: 'Deleting trainer!',
        text: 'You are about to delete this trainer, are you sure about this?',
      });

      if (result) {
        const { status } = await Axios.delete(`${urls.dbApi}/trainers/delete-trainer`, {
          params: { _id },
        });

        success({ text: 'Trainer deleted!', title: 'Success!', button: 'Okay' });

        const copyOfTrainer = prevState.trainers.slice();
        copyOfTrainer.splice(trainerIndex, 1);
        // update trainer Arr in state

        setState({ ...prevState, trainers: copyOfTrainer, error: false });
      }
    } catch (err) {
      console.error(err);
      const { status, message } = err.response.data || {};
      if (status === 500) return; // push over to 500
      setState({ ...prevState, error: true });
    }
  },

  /**
   * Create new trainer and push trainer obj to state.trainers
   * @param {Object} prevState
   * @param {Object} setState
   * @param {String, String, String} options{ name, textInfo, imgLink }
   */
  addTrainer: async (prevState, setState, options, e) => {
    e.preventDefault();
    try {
      const { name, text, imgLink } = options || {};
      const { data } = await Axios.post(`${urls.dbApi}/trainers/new-trainer`, {
        data: { name, text, imgLink },
      });

      const newTrainer = data;

      const updatedTrainers = prevState.trainers.concat(newTrainer);

      setState({ ...prevState, trainers: updatedTrainers, newUserErr: false });

      success({ title: 'Success!', text: 'New trainer has been added.', button: 'Okay' });
    } catch (err) {
      console.error(err);
      const { status } = err.response;
      if (status === 500) return; // push to 500 page
      setState({ ...prevState, newUserErr: true });
    }
  },
};

export default actions;
