import React from 'react';
import { MDBBtn } from 'mdbreact';
import _toPairs from 'lodash/toPairs';
import _replace from 'lodash/replace';
import styles from '../../styles/index.module.scss';
import createInput from '../../../../Components/createInput';

import createInputCollection from 'Utils/createInputCollection';

const createServiceInputs = (servObj, options, storeId, storeIndex, serviceType) => {
  const { name } = servObj || {};
  const { dispatch, actions } = options;

  const servObjArr = _toPairs(servObj);

  const inputCollection = createInputCollection(servObjArr, createInput, {
    className: styles.textArea,
    name,
    hint: 'Fill in info',
    type: 'textarea',
    size: 'lg',
  });

  // converts collection to obj that matches similar format
  // for server to update services information
  const servicesVal = inputCollection.map(([value], i) => {
    const serviceName = servObjArr[i][0];
    return { [serviceName]: value };
  });

  return (
    <section className={_replace(name.toLowerCase(), ' ', '-')}>
      <h3 className="header3">Service: {name}</h3>
      {inputCollection.map(([inputVal, input], i) => (
        <div key={i}>{input}</div>
      ))}
      <MDBBtn
        onClick={(e) =>
          dispatch(actions.updateStoreInformation, { servicesVal, storeId, serviceType }, e)
        }
        className="mb-4"
        color="primary"
        size="sm">
        Update {name}
      </MDBBtn>
    </section>
  );
};

export default function StoreServices(props) {
  const { services, storeId, storeIndex } = props;

  return (
    <section className="stores__services">
      {createServiceInputs(services.breathSaver, props, storeId, storeIndex, 'breathSaver')}
      {createServiceInputs(services.dogTraining, props, storeId, storeIndex, 'dogTraining')}
      {createServiceInputs(services.dogWash, props, storeId, storeIndex, 'dogWash')}
      {createServiceInputs(services.firstCare, props, storeId, storeIndex, 'firstCare')}
    </section>
  );
}
